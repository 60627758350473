import React, { useState, useEffect } from "react";
import "./SupplierCommissionBlockTable.css";
import Table from "@material-ui/core/Table";
import IconButton from "@mui/material/IconButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import useConfigurationTableCell from "../../../../hooks/useConfigurationTableCell";
import useConfigurationTableRow from "../../../../hooks/useConfigurationTableRow";
import cabinClassList from "../../../../data/CabinClass";
import Paper from "@material-ui/core/Paper";
import { Chip } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import Box from "@mui/material/Box";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

export default function SupplierCommissionBlockTable({
  openDrawer,
  setAction,
  commissionBlocks,
  name,
  setPage,
  page,
  pccList,
  loadCommissionBlocks,
}) {
  const StyledTableRow = useConfigurationTableRow;
  const StyledTableCell = useConfigurationTableCell;
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 25));
  //   setPage(0);
  // };

  // const [searchedVal, setSearchedVal] = useState("");
  // const capitalizeEachWord = str => {
  //   return str
  //   .toLowerCase()
  //   .split(' ')
  //   .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  //   .join(' ');
  // };

  useEffect(() => {
    loadCommissionBlocks();
  }, [page]);

  return (
    <div className="supplier-listing">
      {commissionBlocks ? (
        commissionBlocks.length === 0 ? (
          <>
            <Alert severity="error">No record found.</Alert>
          </>
        ) : (
          <React.Fragment>
            <TableContainer component={Paper} className="table-paper-styles">
              <Table className="table-background">
                <TableHead>
                  {/* <TableRow>
                  <TableCell colSpan={8} className="py-2">
                  <OutlinedInput
                      id="keyword-search"
                      onChange={(e) => {setSearchedVal(e.target.value); setPage(0)}}
                      placeholder="Keyword Search..."
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </TableCell>
                </TableRow> */}
                  <TableRow>
                    <TableCell>Supplier Ref ID</TableCell>
                    <TableCell>Pseudo Code</TableCell>
                    <TableCell>Airline</TableCell>
                    <TableCell>FareType</TableCell>
                    <TableCell>Cabin Class</TableCell>
                    <TableCell align="center" width="130">
                      Travel Type
                    </TableCell>
                    {/* <TableCell>IATA</TableCell>
                  <TableCell>IATA Type</TableCell>
                  <TableCell>PLB</TableCell>
                  <TableCell>PLB Type</TableCell> */}
                    <TableCell>Status</TableCell>
                    <TableCell width="750px">Modified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissionBlocks && commissionBlocks.length > 0 ? (
                    commissionBlocks
                      // .filter((row) =>
                      // {
                      //   return row.supplierCommissionBlocks.fareType?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                      //    row.supplierCommissionBlocks.cabinClass?.toLowerCase().includes(
                      //     cabinClassList.filter(id => id.label.includes(capitalizeEachWord(searchedVal.toString().toLowerCase())))
                      //     .map(({ value }) => value)[0]) ||
                      //   row.supplierCommissionBlocks.pccCode?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                      //   row.supplierCommissionBlocks.id == searchedVal;
                      // }
                      //   // note that I've incorporated the searchedVal length check here
                      //   // !searchedVal.length || row.supplierCommissionBlocks.fareType || row.supplierCommissionBlocks.cabinClass
                      //   //   .toString()
                      //   //   .toLowerCase()
                      //   //   .includes(searchedVal.toString().toLowerCase())
                      // )
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((blocks) => (
                        <StyledTableRow
                          key={JSON.stringify(blocks.supplierCommissionBlocks)}
                          onClick={() => {
                            setAction("view");
                            openDrawer(blocks.supplierCommissionBlocks);
                          }}
                        >
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {blocks.supplierCommissionBlocks.id}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {blocks.supplierCommissionBlocks.pccCode}
                            </div>
                            <div className="table-cell-description">
                              {pccList
                                .filter((id) =>
                                  id.value.startsWith(
                                    blocks.supplierCommissionBlocks.pccCode +
                                    ","
                                  )
                                )
                                .map(
                                  ({ label }) => label.match(/\(([^)]+)\)/)[1]
                                )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {blocks.supplierCommissionBlocks.airline}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {blocks.supplierCommissionBlocks.fareType}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {cabinClassList &&
                                blocks.supplierCommissionBlocks.cabinClass
                                  .split(",")
                                  .map((item, i) =>
                                    cabinClassList
                                      .filter((id) => id.value === item)
                                      .map(({ label }) => label)
                                  )
                                  .join(", ")}
                            </div>
                            {/* {cabinClassList &&
                            cabinClassList
                              .filter(
                                (id) =>
                                  id.value ===
                                  blocks.supplierCommissionBlocks.cabinClass
                              )
                              .map((row) => (
                                <div className="table-cell-title">{row.label}</div>
                              ))} */}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <div className="table-cell-title pointer">
                              {blocks.supplierCommissionBlocks.travelType}
                            </div>
                          </StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title pointer">{blocks.supplierCommissionBlocks.iata}</div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title pointer">{blocks.supplierCommissionBlocks.iataType}</div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title pointer">{blocks.supplierCommissionBlocks.plb}</div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <div className="table-cell-title pointer">{blocks.supplierCommissionBlocks.plbType}</div>
                        </StyledTableCell> */}
                          <StyledTableCell>
                            <div className="table-cell-title">
                              {blocks.supplierCommissionBlocks.isActive ? (
                                <Chip
                                  label="active"
                                  color="primary"
                                  size="small"
                                  style={{ backgroundColor: "green" }}
                                />
                              ) : (
                                <Chip
                                  label="in active"
                                  color="primary"
                                  style={{ backgroundColor: "red" }}
                                />
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            <div className="table-cell-description">
                              Created by:{" "}
                              <span style={{ color: "black" }}>
                                {blocks.createdBy}
                              </span>
                            </div>
                            <div className="table-cell-description">
                              Created on:{" "}
                              {blocks.supplierCommissionBlocks.created.replace(
                                "T",
                                " , "
                              )}
                            </div>
                            <div className="table-cell-description">
                              Last Modified by:{" "}
                              <span style={{ color: "black" }}>
                                {blocks.modefiedBy}
                              </span>
                            </div>
                            <div className="table-cell-description">
                              Last Modified on:{" "}
                              {blocks.supplierCommissionBlocks.modified.replace(
                                "T",
                                " , "
                              )}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="50%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="70%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="800%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="500%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="table-loading">
                          <Skeleton width="70%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div className="table-loading">
                          <Skeleton width="80%" height={24} animation="wave" />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25]}
              component="div"
              // count={commissionBlocks.filter((row) =>
              //   {
              //     return row.supplierCommissionBlocks.fareType?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              //     row.supplierCommissionBlocks.cabinClass?.toLowerCase().includes(
              //       cabinClassList.filter(id => id.label.includes(capitalizeEachWord(searchedVal.toString().toLowerCase())))
              //       .map(({ value }) => value)[0]) ||
              //     row.supplierCommissionBlocks.pccCode?.toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
              //     row.supplierCommissionBlocks.id == searchedVal;
              //   }
              //     // note that I've incorporated the searchedVal length check here
              //     // !searchedVal.length || row.supplierCommissionBlocks.fareType || row.supplierCommissionBlocks.cabinClass
              //     //   .toString()
              //     //   .toLowerCase()
              //     //   .includes(searchedVal.toString().toLowerCase())
              //   ).length}
              // rowsPerPage={rowsPerPage}
              // page={page}

              count={commissionBlocks[0].count}
              rowsPerPage="25"
              page={page - 1}
              onChangePage={(event, newPage) =>
                handleChangePage(event, newPage)
              }
              //onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event)}
              ActionsComponent={TablePaginationActions}
            />
          </React.Fragment>
        )
      ) : (
        <>
          <Row>
            <div className="col-12 text-center py-5 loader-cls">
              <Box className="loader-box-content">
                <div className="flt-main-heading py-3">
                  <h3>Please Wait...</h3> We're fetching Supplier Commission
                  Block List...
                </div>
                <CircularProgress />
              </Box>
            </div>
          </Row>
        </>
        //   //  <CircularProgress style={{ marginLeft: "110px", marginTop: "100px" }} />
      )}
    </div>
  );
}
