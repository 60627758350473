import React, { useEffect, useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import airlineDetails from '../../data/AirlineDetails';
import airportDetails from '../../data/AirportDetails';
import { Button } from '@mui/joy';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import SampleItineraryText from '../../data/SampleItineraryText';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import moment from 'moment';
import cabinClass from '../../data/CabinClass';
dayjs.extend(utc); // Use the UTC plugin
const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOC",
    "DEC",
];
const bookingToCabinMap = {
    F: "First",
    R: "First",
    A: "First",
    P: "First",
    C: "Business",
    I: "Business",
    D: "Business",
    J: "Business",
    Z: "Business",
};
function checkAndCleanArrivalTime(arrivalTime) {
    const specialFormatRegex = /^#?\d{4}(A\+1|P\+1)?$/;
    if (specialFormatRegex.test(arrivalTime)) {
        const cleanedTime = arrivalTime.replace(/[#AP]\+1/g, "");
        return cleanedTime.match(/\d+/)[0];
    } else {
        return null;
    }
}

const combineDateAndTime = (toDate, timeStamp, isNextDayArrival) => {
    const currentDate = dayjs();
    const currentMonth = currentDate.month() + 1;
    let date = Number(toDate.slice(0, 2));
    let monthString = toDate.slice(2, 5);
    let month = months.indexOf(monthString.toUpperCase());
    let year = Number(currentDate.year());
    let hours, minutes;
    hours = parseInt(timeStamp.slice(0, 2));
    minutes = parseInt(timeStamp.slice(2, 4));
    if (isNextDayArrival) {
        date = date + 1;
    }
    let combinedDateTime = currentDate
        .set("hour", hours)
        .set("minute", minutes)
        .set("month", month)
        .set("year", year)
        .set("date", date);
    if (combinedDateTime.month() < currentMonth) {
        combinedDateTime = combinedDateTime.set("year", year + 1);
    }
    return combinedDateTime;
};

const convertToStandardTime = (timeStamp, Date, isNextDayArrival) => {
    if (timeStamp) {
        const isSpecialFormat = /^#?\d{4}(A\+1)?$/.test(timeStamp);
        let resultDateTime = null;
        if (isSpecialFormat) {
            let time = checkAndCleanArrivalTime(timeStamp);
            resultDateTime = combineDateAndTime(Date, time, isNextDayArrival);
        } else {
            resultDateTime = combineDateAndTime(Date, timeStamp, isNextDayArrival);
        }
        return resultDateTime;
    } else {
        return null;
    }
};

const parseItinerary = (lines, parser) => {
    let parsedData = [];
    const datePattern = /\b\d{1,2}[A-Z]{3}\b/ig;
    const airportPattern = /([A-Z]{3})([A-Z]{3})/i;
    const timePattern = /#?\d{3,4}[APap]?(?:\+1)?/g;
    for (let line of lines) {
        line = line.replace(/^\d+(\.\s*|\s+)/, '').trim();
        if (!line) continue;
        let airlineCode = null;
        let flightNumber = null;
        let bookingClassCode = null;
        const flightMatch = line.match(/\b([A-Z]{2})\s+(\d{1,4})\s+([A-Z]?)\b/);
        if (flightMatch) {
            airlineCode = flightMatch[1];
            flightNumber = flightMatch[2];
            bookingClassCode = flightMatch[3] || null;
            line = line.replace(flightMatch[0], '').trim();
        }
        const dateMatches = line.match(datePattern);
        const departureDate = dateMatches ? dateMatches[0] : null;
        const arrivalDate = dateMatches && dateMatches[1] ? dateMatches[1] : departureDate;
        const timeMatches = line.match(timePattern);
        let departureTime = null;
        let arrivalTime = null;
        if (timeMatches && timeMatches.length >= 1) {
            departureTime = timeMatches[0];
            arrivalTime = timeMatches.length > 1 ? timeMatches[1] : departureTime;
        }
        let isNextDayArrival = false;
        if (arrivalTime && arrivalTime.includes('#')) {
            arrivalTime = arrivalTime.replace('#', '');
            isNextDayArrival = true;
        }
        if (arrivalTime && arrivalTime.includes('+1')) {
            arrivalTime = arrivalTime.replace('+1', '');
            isNextDayArrival = true;
        }
        departureTime = formatTime(departureTime);
        arrivalTime = formatTime(arrivalTime);
        const airportMatches = line.match(airportPattern);
        const departureAirport = airportMatches ? airportMatches[1] : null;
        const destinationAirport = airportMatches ? airportMatches[2] : null;
        const formattedDepartureDateTime = convertToStandardTime(departureTime, departureDate, false);
        const adjustedArrivalDateTime = convertToStandardTime(arrivalTime, arrivalDate, isNextDayArrival);
        const cabinClassLabel = bookingToCabinMap[bookingClassCode] || "Economy";
        const cabinClassValue = cabinClass.find(v => v.label === cabinClassLabel)?.value;
        const data = {
            airline: airlineCode,
            flightNo: flightNumber,
            bookingClass: bookingClassCode,
            cabinClass: cabinClassValue,
            cabinClassLabel: cabinClassLabel,
            depTime: formattedDepartureDateTime,
            arrTime: adjustedArrivalDateTime,
            depDate: formattedDepartureDateTime,
            arrDate: adjustedArrivalDateTime,
            originAirport: departureAirport,
            destinationAirport: destinationAirport,
            paxCount: 1,
            airlinePNR: "",
            gdsPNR: "",
        };
        parsedData.push(data);
    }

    return parsedData.filter(itinerary => itinerary !== null);
};
const formatTime = (time) => {
    if (!time) return null;
    let isPM = time.toLowerCase().includes('p');
    let isAM = time.toLowerCase().includes('a');
    let formattedTime;
    let hours;
    let minutes;
    if (time.length === 4) {
        if (time.includes('P') || time.includes('A') || time.includes('p') || time.includes('a')) {
            hours = time.slice(0, time.length - 3);
            minutes = time.slice(1, 3);
        }
        else {
            hours = time.slice(0, time.length - 2);
            minutes = time.slice(-2);
        }
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
    } if (time.length === 3) {
        hours = time.slice(0, time.length - 3);
        minutes = time.slice(1, -1);
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
        formattedTime = `${hours}:${minutes}`;
    } else if (time.length === 5) {
        hours = time.slice(0, time.length - 3);
        minutes = time.slice(2, -1);
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
    } else if (time.length > 5) {
        hours = time.slice(0, time.length - 4);
        minutes = time.slice(2, -2);
        if (isPM && hours !== '12') {
            hours = parseInt(hours) + 12;
        } else if (isAM && hours === '12') {
            hours = '0';
        }
    }
    if (hours.length === 1) {
        hours = '0' + hours;
    }
    formattedTime = `${hours}${minutes}`;
    return formattedTime;
};

function SearchItineraryParser({ search, setSearch, itineraryText, setItineraryText, dispatchIncludeAirlines, setModalIsOpen, modalIsOpen, setInputText, inputText, setItineraryData, itineraryData }) {
    useEffect(() => {
        setItineraryText((prevState) => '');
        setItineraryData([]);
    }, [])
    const textFile = new Blob([SampleItineraryText], { type: 'text/plain' });
    const downloadLink = URL.createObjectURL(textFile);
    const [error, setError] = useState(null);
    const [startingRow, setStartingRow] = useState(null);
    const [selectedRadio, setSelectedRadio] = React.useState("oneway");
    const handleChange = (event) => {
        setSelectedRadio(event.target.value);
    };
    const parseItineraryText = () => {

        if (itineraryText && itineraryText?.length > 0) {
            const lines = itineraryText?.split("\n");
            let parsedData = [];
            const parser = {
                name: "Amadeus",
                identifier: "",
            };
            parsedData = parseItinerary(lines, parser);
            console.log(parsedData);
            // Check if any parsed itinerary has a departure date in the past
            const currentDate = dayjs();
            const pastItinerary = parsedData.some(itinerary => itinerary.depDate.isBefore(currentDate));

            if (pastItinerary) {
                setError("The itinerary contains a past date. Please enter a valid itinerary text.");
                setItineraryData([]);
            } else {
                if (parsedData.length === 0) {
                    setError("No valid itineraries found. Please check the format of your text.");
                    setItineraryData([]);
                } else {
                    setError(null);
                    setItineraryData(parsedData);
                }
            }
        }
    };
    const setIncludeAirlineData = async (data) => {
        if (data) {
            let list = [];

            if (typeof (data) === 'string') {
                let string = "";
                list.push(airlineDetails.find((el) => el.Code === data));
                string.length === 0
                    ? (string = data)
                    : (string = string.concat("," + data));
            } else {
                let array = data;
                array.forEach(element => {
                    list.push(airlineDetails.find((el) => el.Code === element));
                });
            }
            dispatchIncludeAirlines({ type: "includeAirlines", newValue: list });
        } else {
            dispatchIncludeAirlines({ type: "includeAirlines", newValue: [] });
        }
    };

    const getAirportData = (airport) => {
        if (airport) {
            let temp = airportDetails.find((el) => el.city_code === airport);
            return temp;
        }
    }
    const addItienaryToSearch = () => {

        const existingSearch = { ...search };
        let onwardData = itineraryData[0];
        let returnData = itineraryData[(startingRow - 1)];
        console.log("onwardData", onwardData, "returnData", returnData);
        existingSearch.depart = (onwardData.depDate);
        existingSearch.return =
            selectedRadio === 'oneway' ? moment(onwardData.depDate).add(1, 'd') :
                returnData && returnData.depDate ?
                    (returnData.depDate) : moment(onwardData.depDate).add(1, 'd');
        console.log("existingSearch.return", existingSearch.return);
        console.log("existingSearch.depart", existingSearch.depart);
        existingSearch.adult = onwardData.paxCount;
        existingSearch.children = 0;
        existingSearch.infant = 0;
        existingSearch.from = getAirportData(onwardData.originAirport);
        existingSearch.type = selectedRadio === 'oneway' ? 'OneWay' : 'RoundTrip';
        if (itineraryData && itineraryData.length === 1) {
            setIncludeAirlineData(onwardData.airline);
            existingSearch.flightNo = onwardData.flightNo;
            existingSearch.includeCarrier = onwardData.airline;
            existingSearch.to = getAirportData(onwardData.destinationAirport);
        }
        else if (itineraryData.length > 1) {
            let flightNoSet = new Set();
            let includeCarrierSet = new Set();
            itineraryData.forEach((data) => {
                flightNoSet.add(data.flightNo);
                includeCarrierSet.add(data.airline);
            });
            setIncludeAirlineData(Array.from(includeCarrierSet));
            existingSearch.flightNo = Array.from(flightNoSet);
            existingSearch.includeCarrier = Array.from(includeCarrierSet).join(',');
            let airport = selectedRadio === 'oneway' ? itineraryData[(itineraryData.length - 1)].destinationAirport : itineraryData[(startingRow - 1)].originAirport;
            existingSearch.to = getAirportData(airport);
        }
        existingSearch.pasteSearchEnabled = true;
        console.log("Search data before assign", existingSearch);
        localStorage.setItem("lastSearch", JSON.stringify(existingSearch));
        setSearch((prevState) => ({ ...existingSearch }));
        setModalIsOpen(false)

    }
    return (
        <div style={{ zIndex: 50 }}>

            <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} sx={{ maxWidth: '70vw', maxHeight: '70vh', overflowX: 'auto', overflowY: 'scroll', marginTop: '10vh', }}>
                <Sheet
                    variant="outlined"
                    sx={{
                        // maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        maxHeight: '60vw',
                        overflowY: 'scroll'
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <br />
                    <p style={{ textTransform: "capitalize", color: "red" }}>
                        Ensure to Enter Valid Itinerary Text in a valid format.
                    </p>
                    <p style={{ textTransform: "capitalize", color: "red" }}>
                        Ensure to Enter only Itinerary Text in the area.
                    </p>
                    <br />
                    <div className="m-3" style={{ display: 'flex' }}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={selectedRadio}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="oneway"
                                    control={<Radio />}
                                    label="Oneway"
                                />
                                <FormControlLabel
                                    value="roundtrip"
                                    control={<Radio />}
                                    label="Round Trip"
                                />
                            </RadioGroup>
                        </FormControl>
                        {
                            selectedRadio === "roundtrip" ?
                                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                    <span>Return Starting Row</span>
                                    <input type='number' style={{ marginLeft: '10px', maxWidth: '30%', maxHeight: '30px' }} onChange={(e) => setStartingRow(e.target.value)} value={startingRow} />
                                </div>
                                : ""
                        }
                    </div>
                    <br />
                    {
                        selectedRadio === "roundtrip" && startingRow && !itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>Please Enter Itinerary Text</p> :
                            selectedRadio === "oneway" && !itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>Please Enter Itinerary Text</p> :
                                selectedRadio === "roundtrip" && !startingRow && itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>Please Enter Return Trip Starting Row</p> :
                                    selectedRadio === "roundtrip" && !startingRow && !itineraryText ? <p style={{ textTransform: "capitalize", color: "red" }}>Please Enter Itinerary Text And Return Trip Starting Row</p> : ""
                    }
                    <br />
                    <textarea
                        rows="5"
                        cols="80"
                        value={itineraryText}
                        onChange={(e) => { setItineraryText(e.target.value) }}
                        placeholder="Paste itinerary text here"
                    />
                    <br />{
                        (selectedRadio === "roundtrip" && startingRow && itineraryText) || (selectedRadio === "oneway" && itineraryText) ? <Button variant='outlined' style={{ float: 'left', marginLeft: '10px', color: '#0a3622', borderColor: '#0a3622' }} onClick={parseItineraryText}>Parse Itinerary</Button> : ""
                    }
                    {/* <Button variant='outlined' style={{ float: 'left', marginLeft: '10px', color: '#0a3622', borderColor: '#0a3622' }} onClick={parseItineraryText}>Parse Itinerary</Button> */}
                    {((selectedRadio === "roundtrip" && startingRow && itineraryText) || (selectedRadio === "oneway" && itineraryText)) && itineraryData && itineraryData.length > 0 ? <Button variant='outlined' style={{ float: 'right', marginLeft: '10px', color: '#d63384', borderColor: '#d63384' }} onClick={addItienaryToSearch}>Add to Search</Button> : ""}
                    <br />
                    <br />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {(itineraryData && itineraryData.length > 0 && !error) && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3}>Airline</TableCell>
                                    <TableCell colSpan={3}>Pax Count</TableCell>
                                    <TableCell colSpan={3}>FlightNo </TableCell>
                                    <TableCell colSpan={3}>Departure </TableCell>
                                    {
                                        selectedRadio === "roundtrip" ? <TableCell colSpan={3}>Arrival </TableCell> : ''
                                    }

                                    <TableCell colSpan={3}>From </TableCell>
                                    <TableCell colSpan={3}>To </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itineraryData.map((flight, index) => (
                                    <TableRow key={`${index + 'pob'}`}>
                                        <TableCell colSpan={3}>{flight.airline}</TableCell>
                                        <TableCell colSpan={3}>{flight.paxCount}</TableCell>
                                        <TableCell colSpan={3}>{flight.flightNo}</TableCell>
                                        <TableCell colSpan={3}>{moment(flight?.depDate?.$d).format("DD/MM/YYYY") +
                                            " " +
                                            moment(flight?.depDate?.$d).format("HH:mm")}</TableCell>
                                        {
                                            selectedRadio === "roundtrip" ? <TableCell colSpan={3}>{moment(flight?.arrDate?.$d).format("DD/MM/YYYY") +
                                                " " +
                                                moment(flight?.arrDate?.$d).format("HH:mm")}</TableCell> : ''
                                        }
                                        <TableCell colSpan={3}>{flight.originAirport}</TableCell>
                                        <TableCell colSpan={3}>{flight.destinationAirport}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Sheet>
            </Modal>
        </div>
    );
}

SearchItineraryParser.propTypes = {
    search: PropTypes.object.isRequired,
    setSearch: PropTypes.func.isRequired,
    dispatchIncludeAirlines: PropTypes.func.isRequired,
    setModalIsOpen: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    setInputText: PropTypes.func.isRequired,
    inputText: PropTypes.string.isRequired,
    setItineraryData: PropTypes.func.isRequired,
    itineraryData: PropTypes.array.isRequired,
};
export default SearchItineraryParser;


