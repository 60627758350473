import { Table } from "@mui/joy";
import React, { useEffect, useRef } from 'react';
import BootstrapTooltip1 from "../BootstrapToolTip/BootstrapToolTip1";

export default function TablePNR({ flight, maxHeight }) {
    const tableBodyRef = useRef(null);
    useEffect(() => {
        debugger;
        if (tableBodyRef.current && maxHeight > 0) {
            // const columns = tableBodyRef.current.children;
            // // Apply the maxHeight to all columns
            // for (const column of columns) {
            //     column.style.height = `${maxHeight}px`;
            // }
            flight?.segGroup?.segments?.map((segment, index) => {
                const tableRow = document.getElementById('seggroupPNRTable' + index);
                console.log("tableRow", tableRow);
                const columns = tableRow.getElementsByTagName('td');
                console.log("columns before height", columns);
                for (const column of columns) {
                    column.style.height = `${maxHeight}px`;
                }
                console.log("columns after height", columns);
            })

        }
    }, [maxHeight, tableBodyRef, flight]);
    return (
        <>

            {
                flight?.segGroups && flight?.segGroups?.length > 0 ? (
                    flight?.segGroups?.map((segGroup, index) => (
                        <div className="flight-itenary">
                            <div className="flight-itenary-header">
                                <div className="flight-itenary-title-container">
                                    <div>
                                        PNR
                                    </div>
                                </div>
                            </div>
                            <Table aria-label="basic table" id="tablePNR">
                                <thead>
                                    <tr style={{ display: 'flex' }}>
                                        <BootstrapTooltip1 title="RBD">
                                            <th align="center" style={{ flex: '1 1 auto' }}>RBD</th>
                                        </BootstrapTooltip1>
                                        <BootstrapTooltip1 title="Fare Basis">
                                            <th align="center" style={{ flex: '1 1 auto' }}>Fare Basis</th>
                                        </BootstrapTooltip1>
                                        <BootstrapTooltip1 title="GDS PNR">
                                            <th align="center" style={{ flex: '1 1 auto' }}>GDS PNR</th>
                                        </BootstrapTooltip1>
                                    </tr>
                                </thead>
                                <tbody ref={tableBodyRef}>
                                    {
                                        segGroup?.segments?.map((segment, index) => (
                                            <tr id={`seggroupPNRTable${index}`} style={{ display: 'flex' }}>
                                                <BootstrapTooltip1 title={segment?.rbd}><td align="center" style={{ flex: '1 1 auto' }}>{segment?.rbd}</td></BootstrapTooltip1>
                                                <BootstrapTooltip1 title={flight?.fareBasis ? flight?.fareBasis : '-'}><td align="center" style={{ flex: '1 1 auto' }}>{flight?.fareBasis ? flight?.fareBasis : '-'}</td></BootstrapTooltip1>
                                                <BootstrapTooltip1 title={flight?.pnr}><td align="center" style={{ flex: '1 1 auto' }}>{flight?.pnr}</td></BootstrapTooltip1>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    ))
                ) : ''
            }

        </>

    );
}