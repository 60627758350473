const PaymentStatus = {
  1: "Pending",
  2: "Success",
  3: "Failure",
  4: "Aborted",
  5: "Invalid",
  6: "Timeout",
  7: "Auto_Cancelled",
  8: "Auto_Reversed",
  9: "Awaited",
  10: "Cancelled",
  11: "Fraud",
  12: "Initiated",
  13: "Refunded",
  15: "System_refund",
  16: "Unsuccessful",
};
export default PaymentStatus;
