import React, { Fragment, useEffect, useState } from "react";
import "react-multi-style-dropdown-tree/lib/index.css";
import "./SupplierCommissionBlockDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

// import MomentUtils from "@date-io/moment";
import moment from "moment";
import countries from "../../../../data/Country";
import cabinClassList from "../../../../data/CabinClass";
import fareBasisTypeList from "../../../../data/FareBasisType";
import fareTypeList from "../../../../data/FareType";
import TripTypeList from "../../../../data/TripType";
import airlineDetails from "../../../../data/AirlineDetails";
import airportDetails from "../../../../data/AirportDetails";
import taxCategory from "../../../../data/TaxCategory";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { KeyboardDatePicker } from "@material-ui/pickers";
import axios from "axios";
import { TextField } from "@material-ui/core";
import { isUserHasPermission } from "../../../../services/AuthService";
import { formatDate } from "../../../../services/dateFormatter";
import RiseLoading from "../../../Loader/RiseLoader";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
const calTypes = ["Percentage", "Value"];
const statusList = ["true", "false"];
const noneArr = [{ Code: "None", Name: "None" }];
const paxTypeList = ["ADT", "CHD", "INF"];
const extendedAirlineDetails = [...airlineDetails, ...noneArr];
function getStyles(name, pccCode, theme) {
  return {
    fontWeight:
      pccCode.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStylesMultiselect(value, cabinClassType, theme) {
  return {
    fontWeight:
      cabinClassType !== undefined && cabinClassType.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const SelectProps = {
  multiple: true,
};

export default function SupplierCommissionBlockDrawer({
  toggleDrawer,
  selectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  pccList,
  name,
  groupId,
  commissionBlocks,
}) {
  const theme = useTheme();
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setpccCode(
  //     // On autofill we get a the stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  //   console.log(pccCode);
  // };

  // added if condition to make emty array into undefined to prevent unnecessary binding

  if (commissionBlocks?.length == 0) {
    commissionBlocks = undefined;
  }
  // console.log(commissionBlocks);

  const handleChangeAll = (event, setValue) => {
    const {
      target: { value },
    } = event;
    if (value.indexOf("All") !== -1) {
      const allArr = ["All"];
      setValue(allArr);
    } else {
      setValue(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const classes = useStyles();

  const [id, setid] = useState("");
  const [isActive, setisActive] = useState("false");
  const [createdBy, setcreatedBy] = useState("");
  const [created, setcreated] = useState("");
  const [modifiedBy, setmodifiedBy] = useState("");
  const [modified, setmodified] = useState("");
  const [supplierCommissionGroupId, setsupplierCommissionGroupId] =
    useState("");
  const [supplierName, setsupplierName] = useState("");
  const [pccCode, setpccCode] = useState("");
  const [airline, setairline] = useState([]);
  const [fareType, setfareType] = useState([]);
  const [travelType, settravelType] = useState([]);
  const [rbd, setrbd] = useState("");
  const [exRBD, setexRBD] = useState("");
  const [cabinClass, setcabinClass] = useState([]);
  const [flightNumbers, setflightNumbers] = useState("");
  const [exFlightNumbers, setexFlightNumbers] = useState("");

  const [bookingDateFrom, setbookingDateFrom] = useState(null);
  const [exBookingDateFrom, setexBookingDateFrom] = useState(null);
  const [bookingDateTo, setbookingDateTo] = useState(null);
  const [exBookingDateTo, setexBookingDateTo] = useState(null);
  const [travelDateFrom, settravelDateFrom] = useState(null);
  const [travelDateTo, settravelDateTo] = useState(null);
  const [blockoutDateFrom, setblockoutDateFrom] = useState(null);
  const [blockoutDateTo, setblockoutDateTo] = useState(null);
  const [outboundDateFrom, setoutboundDateFrom] = useState(null);
  const [outboundDateTo, setoutboundDateTo] = useState(null);

  const [inputbookingDateFrom, setInputbookingDateFrom] = useState();
  const [inputExBookingDateFrom, setInputExBookingDateFrom] = useState();
  const [inputexBookingDateTo, setInputexBookingDateTo] = useState();
  const [inputbookingDateTo, setInputbookingDateTo] = useState();
  const [inputoutboundDateFrom, setInputoutboundDateFrom] = useState();
  const [inputoutboundDateTo, setInputoutboundDateTo] = useState();
  const [inputtravelDateFrom, setInputtravelDateFrom] = useState();
  const [inputtravelDateTo, setInputtravelDateTo] = useState();
  const [inputblockOutDateFrom, setInputblockOutDateFrom] = useState();
  const [inputblockOutDateTo, setInputblockOutDateTo] = useState();

  const [fareBasisType, setfareBasisType] = useState("");
  const [fareBasis, setfareBasis] = useState("");
  //const [exFareBasisType, setexFareBasisType] = useState([]);
  const [exFareBasisType, setexFareBasisType] = useState("");
  const [exFareBasis, setexFareBasis] = useState("");
  const [iata, setiata] = useState("");
  const [plb, setplb] = useState("");
  const [iataType, setiataType] = useState("Percentage");
  const [plbType, setplbType] = useState("Percentage");
  const [taxiataType, settaxiataType] = useState("");
  const [taxplbType, settaxplbType] = useState("");
  const [dropnet, setdropnet] = useState("");
  const [markup, setmarkup] = useState("");
  const [taxType, settaxType] = useState("");
  const [vendortaxType, setvendortaxType] = useState("");
  const [taxIATA, settaxIATA] = useState("");
  const [taxPLB, settaxPLB] = useState("");
  const [vendorIATA, setvendorIATA] = useState("");
  const [vendorPLB, setvendorPLB] = useState("");
  const [vendorIATAType, setvendorIATAType] = useState("Percentage");
  const [vendorPLBType, setvendorPLBType] = useState("Percentage");
  const [vendortaxIATAType, setvendortaxIATAType] = useState("");
  const [vendortaxPLBType, setvendortaxPLBType] = useState("");
  const [vendorDropnet, setvendorDropnet] = useState("");
  const [vendorTaxIATA, setvendorTaxIATA] = useState("");
  const [vendorTaxPLB, setvendorTaxPLB] = useState("");

  const [originAirport, setoriginAirport] = useState([]);
  const [exOriginAirport, setexOriginAirport] = useState([]);
  const [destinationAirport, setdestinationAirport] = useState([]);
  const [exDestinationAirport, setexDestinationAirport] = useState([]);
  const [originCountry, setoriginCountry] = useState([]);
  const [exOriginCountry, setexOriginCountry] = useState([]);
  const [destinationCountry, setdestinationCountry] = useState([]);
  const [exDestinationCountry, setexDestinationCountry] = useState([]);
  const [codeShareAirline, setcodeShareAirline] = useState([]);
  const [excodeShareAirline, setexcodeShareAirline] = useState([]);
  const [connectingAirport, setconnectingAirport] = useState([]);
  const [exconnectingAirport, setexconnectingAirport] = useState([]);
  const [interlineAirline, setinterlineAirline] = useState([]);
  const [exinterlineAirline, setexinterlineAirline] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [tourCode, settourCode] = useState("");
  const [paxType, setpaxType] = useState([]);
  const [originArea, setoriginArea] = useState("");
  const [destinationArea, setdestinationArea] = useState("");
  const [exOriginArea, setexOriginArea] = useState("");
  const [exDestinationArea, setexDestinationArea] = useState("");
  const [originAreaSelectedValues, setOriginAreaSelectedValues] = useState([]);
  const [destinationAreaSelectedValues, setDestinationAreaSelectedValues] =
    useState([]);
  const [exOriginAreaSelectedValues, setexOriginAreaSelectedValues] = useState(
    []
  );
  const [exDestinationAreaSelectedValues, setexDestinationAreaSelectedValues] =
    useState([]);

  const [copyFrom, setCopyFrom] = useState("");

  let dummydata = [
    {
      id: "3",
      value: "Europe",
    },
    {
      id: "0.1",
      value: "Angola",
    },
    {
      id: "0.2",
      value: "Burkina Faso",
    },
    {
      id: "0.3",
      value: "Burundi",
    },
    {
      id: "0.4",
      value: "Benin",
    },
  ];

  useEffect(() => {
    if (copyFrom === "") {
      resetAllTheStates();
    } else {
      setRespectiveStates("new", copyFrom);
    }
  }, [copyFrom]);

  useEffect(() => {
    if (action === "edit" || action === "view") {
      setRespectiveStates(action, selectedItem);
    } else {
      resetAllTheStates();
    }
  }, [toggleDrawer, action]);

  // useEffect(() => {
  //   // console.log("inputbookingDateFrom useeffect", inputbookingDateFrom);
  // }, [inputbookingDateFrom]);
  // useEffect(() => {
  //   console.log("inputExBookingDateFrom useeffect", inputExBookingDateFrom);
  // }, [inputExBookingDateFrom]);

  const setRespectiveStates = (action, item) => {
    action == "new" ? setid("") : setid(item.id);
    setisActive(item.isActive.toString());
    setcreatedBy(item.createdBy);
    setcreated(item.created);
    setmodifiedBy(item.modifiedBy);
    setmodified(item.modified);
    setsupplierCommissionGroupId(item.supplierCommissionGroupId);
    setsupplierName(item.supplierName);
    setpccCode(item.pccCode + "," + item.supplierName);

    if (item.travelType !== undefined && item.travelType !== "") {
      let list = [];
      item.travelType
        .split(",")
        .map((trip) => list.push(TripTypeList.find((el) => el === trip)));
      settravelType(list);
    } else {
      settravelType([]);
    }
    if (item.cabinClass !== undefined && item.cabinClass !== "") {
      let list = [];
      item.cabinClass
        .split(",")
        .map((cabin) =>
          list.push(cabinClassList.find((el) => el.value === cabin))
        );
      setcabinClass(list);
    } else {
      setcabinClass([]);
    }

    if (item.airline !== undefined && item.airline !== "") {
      let list = [];
      item.airline
        .split(",")
        .map((air) =>
          list.push(extendedAirlineDetails.find((el) => el.Code === air))
        );
      setairline(list);
    } else {
      setairline([]);
    }
    if (item.fareType !== undefined && item.fareType !== "") {
      let list = [];
      item.fareType
        .split(",")
        .map((fare) => list.push(fareTypeList.find((el) => el === fare)));
      setfareType(list);
    } else {
      setfareType([]);
    }

    // if (item.fareBasisType !== undefined && item.fareBasisType !== "") {
    //   let list = [];
    //   item.fareBasisType.split(",").map(fareBasistype => list.push(fareBasisTypeList.find(el => el === fareBasistype)));
    //   setfareBasisType(list);
    // } else {
    //   setfareBasisType([]);
    // }

    // if (item.exFareBasisType !== undefined && item.exFareBasisType !== "") {
    //   let list = [];
    //   item.exFareBasisType.split(",").map(exfareBasistype => list.push(fareBasisTypeList.find(el => el === exfareBasistype)));
    //   setexFareBasisType(list);
    // } else {
    //   setexFareBasisType([]);
    // }
    setfareBasisType(selectedItem.fareBasisType);
    setexFareBasisType(selectedItem.exFareBasisType);
    setrbd(item.rbd);
    setexRBD(item.exRBD);
    setflightNumbers(item.flightNumbers);
    setexFlightNumbers(item.exFlightNumbers);

    setbookingDateFrom(
      item.bookingDateFrom ? formatDate(item.bookingDateFrom) : null
    );
    setexBookingDateFrom(
      item.exBookingDateFrom ? formatDate(item.exBookingDateFrom) : null
    );
    setbookingDateTo(
      item.bookingDateTo ? formatDate(item.bookingDateTo) : null
    );
    setexBookingDateTo(
      item.exBookingDateTo ? formatDate(item.exBookingDateTo) : null
    );
    settravelDateFrom(
      item.travelDateFrom ? formatDate(item.travelDateFrom) : null
    );
    settravelDateTo(item.travelDateTo ? formatDate(item.travelDateTo) : null);
    setblockoutDateFrom(
      item.blockoutDateFrom ? formatDate(item.blockoutDateFrom) : null
    );
    setblockoutDateTo(
      item.blockoutDateTo ? formatDate(item.blockoutDateTo) : null
    );
    setoutboundDateFrom(
      item.outboundDateFrom ? formatDate(item.outboundDateFrom) : null
    );
    setoutboundDateTo(
      item.outboundDateTo ? formatDate(item.outboundDateTo) : null
    );

    setInputExBookingDateFrom(
      item.exBookingDateFrom ? item.exBookingDateFrom : null
    );
    setInputbookingDateFrom(item.bookingDateFrom ? item.bookingDateFrom : null);
    setInputexBookingDateTo(item.exBookingDateTo ? item.exBookingDateTo : null);
    setInputbookingDateTo(item.bookingDateTo ? item.bookingDateTo : null);
    setInputtravelDateFrom(item.travelDateFrom ? item.travelDateFrom : null);
    setInputtravelDateTo(item.travelDateTo ? item.travelDateTo : null);
    setInputblockOutDateFrom(
      item.blockoutDateFrom ? item.blockoutDateFrom : null
    );
    setInputblockOutDateTo(item.blockoutDateTo ? item.blockoutDateTo : null);
    setInputoutboundDateFrom(
      item.outboundDateFrom ? item.outboundDateFrom : null
    );
    setInputoutboundDateTo(item.outboundDateTo ? item.outboundDateTo : null);

    setfareBasis(item.fareBasis);
    setexFareBasis(item.exFareBasis);
    setiata(item.iata);
    setplb(item.plb);
    setmarkup(item.markup);
    settaxType(item.taxType);
    setvendortaxType(item.vendorTaxType);
    settaxIATA(item.taxIATA);
    settaxPLB(item.taxPLB);
    setvendorIATA(item.vendorIATA);
    setvendorPLB(item.vendorPLB);
    setvendorTaxIATA(item.vendorTaxIATA);
    setvendorTaxPLB(item.vendorTaxPLB);
    setiataType(item.iataType);
    settaxiataType(item.taxIATAType);
    setvendorIATAType(item.vendorIATAType);
    setvendortaxIATAType(item.vendorTaxIATAType);
    setplbType(item.plbType);
    settaxplbType(item.taxPLBType);
    setvendorPLBType(item.vendorPLBType);
    setvendortaxPLBType(item.vendorTaxPLBType);
    setdropnet(item.dropNet);
    setvendorDropnet(item.vendorDropNet);

    settourCode(item.tourCode);

    if (item.paxType !== undefined && item.paxType !== "") {
      let list = [];
      item.paxType
        .split(",")
        .map((get_pax) => list.push(paxTypeList.find((el) => el === get_pax)));
      setpaxType(list);
    } else {
      setpaxType([]);
    }

    if (item.originAirport !== undefined && item.originAirport !== "") {
      let list = [];
      item.originAirport
        .split(",")
        .map((air) =>
          list.push(airportDetails.find((el) => el.city_code === air))
        );
      setoriginAirport(list);
    } else {
      setoriginAirport([]);
    }
    if (
      item.destinationAirport !== undefined &&
      item.destinationAirport !== ""
    ) {
      let list = [];
      item.destinationAirport
        .split(",")
        .map((air) =>
          list.push(airportDetails.find((el) => el.city_code === air))
        );
      setdestinationAirport(list);
    } else {
      setdestinationAirport([]);
    }
    if (item.exOriginAirport !== undefined && item.exOriginAirport !== "") {
      let list = [];
      item.exOriginAirport
        .split(",")
        .map((air) =>
          list.push(airportDetails.find((el) => el.city_code === air))
        );
      setexOriginAirport(list);
    } else {
      setexOriginAirport([]);
    }
    if (
      item.exDestinationAirport !== undefined &&
      item.exDestinationAirport !== ""
    ) {
      let list = [];
      item.exDestinationAirport
        .split(",")
        .map((air) =>
          list.push(airportDetails.find((el) => el.city_code === air))
        );
      setexDestinationAirport(list);
    } else {
      setexDestinationAirport([]);
    }

    if (item.originCountry !== undefined && item.originCountry !== "") {
      let list = [];
      item.originCountry
        .split(",")
        .map((air) => list.push(countries.find((el) => el.code === air)));
      setoriginCountry(list);
    } else {
      setoriginCountry([]);
    }
    if (
      item.destinationCountry !== undefined &&
      item.destinationCountry !== ""
    ) {
      let list = [];
      item.destinationCountry
        .split(",")
        .map((air) => list.push(countries.find((el) => el.code === air)));
      setdestinationCountry(list);
    } else {
      setdestinationCountry([]);
    }
    if (item.exOriginCountry !== undefined && item.exOriginCountry !== "") {
      let list = [];
      item.exOriginCountry
        .split(",")
        .map((air) => list.push(countries.find((el) => el.code === air)));
      setexOriginCountry(list);
    } else {
      setexOriginCountry([]);
    }
    if (
      item.exDestinationCountry !== undefined &&
      item.exDestinationCountry !== ""
    ) {
      let list = [];
      item.exDestinationCountry
        .split(",")
        .map((air) => list.push(countries.find((el) => el.code === air)));
      setexDestinationCountry(list);
    } else {
      setexDestinationCountry([]);
    }

    if (item.codeShareAirline !== undefined && item.codeShareAirline !== "") {
      let list = [];
      item.codeShareAirline
        .split(",")
        .map((air) =>
          list.push(extendedAirlineDetails.find((el) => el.Code === air))
        );
      setcodeShareAirline(list);
    } else {
      setcodeShareAirline([]);
    }
    if (
      item.exCodeShareAirline !== undefined &&
      item.exCodeShareAirline !== ""
    ) {
      let list = [];
      item.exCodeShareAirline
        .split(",")
        .map((air) =>
          list.push(extendedAirlineDetails.find((el) => el.Code === air))
        );
      setexcodeShareAirline(list);
    } else {
      setexcodeShareAirline([]);
    }
    if (item.interlineAirline !== undefined && item.interlineAirline !== "") {
      let list = [];
      item.interlineAirline
        .split(",")
        .map((air) =>
          list.push(extendedAirlineDetails.find((el) => el.Code === air))
        );
      setinterlineAirline(list);
    } else {
      setinterlineAirline([]);
    }
    if (
      item.setexInterlineAirline !== undefined &&
      item.setexInterlineAirline !== ""
    ) {
      let list = [];
      item.setexInterlineAirline
        .split(",")
        .map((air) =>
          list.push(extendedAirlineDetails.find((el) => el.Code === air))
        );
      setexinterlineAirline(list);
    } else {
      setexinterlineAirline([]);
    }

    if (item.connectingAirport !== undefined && item.connectingAirport !== "") {
      let list = [];
      item.connectingAirport
        .split(",")
        .map((air) =>
          list.push(airportDetails.find((el) => el.city_code === air))
        );
      setconnectingAirport(list);
    } else {
      setconnectingAirport([]);
    }
    if (
      item.exConnectingAirport !== undefined &&
      item.exConnectingAirport !== ""
    ) {
      let list = [];
      item.exConnectingAirport
        .split(",")
        .map((air) =>
          list.push(airportDetails.find((el) => el.city_code === air))
        );
      setexconnectingAirport(list);
    } else {
      setexconnectingAirport([]);
    }
  };

  const resetAllTheStates = () => {
    //setOriginAreaSelectedValues(dummydata);
    //console.log(originAreaSelectedValues)
    setid("");
    setisActive("false");
    setcreatedBy("");
    setcreated("");
    setmodifiedBy("");
    setmodified("");
    setsupplierCommissionGroupId(groupId);
    setsupplierName("");
    setpccCode("");
    setairline([]);
    setfareType([]);
    settravelType([]);
    setrbd("");
    setexRBD("");
    setcabinClass([]);
    setflightNumbers("");
    setexFlightNumbers("");

    setbookingDateFrom(null);
    setexBookingDateFrom(null);
    setbookingDateTo(null);
    setexBookingDateTo(null);
    settravelDateFrom(null);
    settravelDateTo(null);
    setblockoutDateFrom(null);
    setblockoutDateTo(null);
    setoutboundDateFrom(null);
    setoutboundDateTo(null);

    setInputbookingDateFrom(null);
    setInputExBookingDateFrom(null);
    setInputbookingDateTo(null);
    setInputexBookingDateTo(null);
    setInputtravelDateFrom(null);
    setInputtravelDateTo(null);
    setInputblockOutDateFrom(null);
    setInputblockOutDateTo(null);
    setInputoutboundDateFrom(null);
    setInputoutboundDateTo(null);

    setfareBasisType("");
    setfareBasis("");
    setexFareBasisType("");
    setexFareBasis("");
    setiata("");
    setplb("");
    setiataType("Percentage");
    setplbType("Percentage");
    setmarkup("");
    settaxType("0");
    setvendortaxType("0");
    settaxIATA("");
    settaxPLB("");
    setvendorIATA("");
    setvendorPLB("");
    setvendorIATAType("Percentage");
    setvendorPLBType("Percentage");
    setvendorTaxIATA("");
    setvendorTaxPLB("");
    setoriginAirport([]);
    setexOriginAirport([]);
    setdestinationAirport([]);
    setexDestinationAirport([]);

    setoriginCountry([]);
    setexOriginCountry([]);
    setdestinationCountry([]);
    setexDestinationCountry([]);
    setcodeShareAirline([]);
    setexcodeShareAirline([]);
    setinterlineAirline([]);
    setexinterlineAirline([]);
    setconnectingAirport([]);
    setexconnectingAirport([]);
    setdropnet("");
    setvendorDropnet("");

    settourCode("");
    setpaxType([]);
  };

  const handleArrayToString = (arrayList, field) => {
    let strValue = "";
    if (arrayList !== undefined && arrayList.length > 0) {
      if (field === "value") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.value)
            : (strValue = strValue + "," + arr.value)
        );
      } else if (field === "Code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.Code)
            : (strValue = strValue + "," + arr.Code)
        );
      } else if (field === "city_code") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.city_code)
            : (strValue = strValue + "," + arr.city_code)
        );
      } else if (field === "countrycode") {
        arrayList.map((arr) =>
          strValue === ""
            ? (strValue = arr.code)
            : (strValue = strValue + "," + arr.code)
        );
      } else if (field === "raw") {
        arrayList.map((arr) =>
          strValue === "" ? (strValue = arr) : (strValue = strValue + "," + arr)
        );
      }
    }
    return strValue;
  };

  async function handleUpdate() {
    setShowLoader(true);
    const headers = {
      "Content-Type": "application/json",
    };
    if (action === "edit" || action === "view") {
      await axios
        .put(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/SupplierCommissionBlock/" +
          selectedItem.id,
          JSON.stringify({
            id: selectedItem.id,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy:
              selectedItem.createdBy === undefined
                ? ""
                : selectedItem.createdBy,
            created:
              selectedItem.created === undefined ? "" : selectedItem.created,
            modifiedBy: "",
            modified:
              selectedItem.created === undefined ? "" : selectedItem.created,
            supplierCommissionGroupId:
              supplierCommissionGroupId === undefined
                ? groupId
                : supplierCommissionGroupId,
            supplierName: pccCode === undefined ? "" : pccCode.split(",")[1],
            pccCode: pccCode === undefined ? "" : pccCode.split(",")[0],

            travelType: handleArrayToString(travelType, "raw"),
            cabinClass: handleArrayToString(cabinClass, "value"),
            airline: handleArrayToString(airline, "Code"),
            fareType: handleArrayToString(fareType, "raw"),

            rbd: rbd === undefined ? "" : rbd,
            exRBD: exRBD === undefined ? "" : exRBD,
            flightNumbers: flightNumbers === undefined ? "" : flightNumbers,
            exFlightNumbers:
              exFlightNumbers === undefined ? "" : exFlightNumbers,
            bookingDateFrom: inputbookingDateFrom
              ? moment(inputbookingDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            bookingDateTo: inputbookingDateTo
              ? moment(inputbookingDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            exBookingDateFrom: inputExBookingDateFrom
              ? moment(inputExBookingDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            exBookingDateTo: inputexBookingDateTo
              ? moment(inputexBookingDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            travelDateFrom: inputtravelDateFrom
              ? moment(inputtravelDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            travelDateTo: inputtravelDateTo
              ? moment(inputtravelDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            blockoutDateFrom: inputblockOutDateFrom
              ? moment(inputblockOutDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            blockoutDateTo: inputblockOutDateTo
              ? moment(inputblockOutDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            outboundDateFrom: inputoutboundDateFrom
              ? moment(inputoutboundDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            outboundDateTo: inputoutboundDateTo
              ? moment(inputoutboundDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            fareBasisType:
              fareBasisType === undefined || fareBasisType === ""
                ? "0"
                : fareBasisType,
            fareBasis: fareBasis === undefined ? "" : fareBasis,
            exFareBasisType:
              exFareBasisType === undefined || exFareBasisType === ""
                ? "0"
                : exFareBasisType,
            exFareBasis: exFareBasis === undefined ? "" : exFareBasis,
            iata: iata === undefined || iata === "" ? "0" : iata,
            plb: plb === undefined || plb === "" ? "0" : plb,
            markup: markup === undefined || markup === "" ? "0" : markup,
            dropnet: dropnet === undefined || dropnet === "" ? "0" : dropnet,
            vendorDropnet:
              vendorDropnet === undefined || vendorDropnet === ""
                ? "0"
                : vendorDropnet,
            taxType: taxType === undefined || taxType === "" ? "0" : taxType,
            vendortaxType:
              vendortaxType === undefined || vendortaxType === ""
                ? "0"
                : vendortaxType,
            taxIATA: taxIATA === undefined || taxIATA === "" ? "0" : taxIATA,
            taxPLB: taxPLB === undefined || taxPLB === "" ? "0" : taxPLB,
            vendorIATA:
              vendorIATA === undefined || vendorIATA === "" ? "0" : vendorIATA,
            vendorPLB:
              vendorPLB === undefined || vendorPLB === "" ? "0" : vendorPLB,
            vendorTaxIATA:
              vendorTaxIATA === undefined || vendorTaxIATA === ""
                ? "0"
                : vendorTaxIATA,
            vendorTaxPLB:
              vendorTaxPLB === undefined || vendorTaxPLB === ""
                ? "0"
                : vendorTaxPLB,
            iataType: iataType === undefined ? "" : iataType,
            taxiataType: taxiataType === undefined ? "" : taxiataType,
            vendorIATAType: vendorIATAType === undefined ? "" : vendorIATAType,
            vendortaxIATAType:
              vendortaxIATAType === undefined ? "" : vendortaxIATAType,
            plbType: plbType === undefined ? "" : plbType,
            taxplbType: taxplbType === undefined ? "" : taxplbType,
            vendorPLBType: vendorPLBType === undefined ? "" : vendorPLBType,
            vendortaxPLBType:
              vendortaxPLBType === undefined ? "" : vendortaxPLBType,

            originAirport: handleArrayToString(originAirport, "city_code"),
            destinationAirport: handleArrayToString(
              destinationAirport,
              "city_code"
            ),
            exOriginAirport: handleArrayToString(exOriginAirport, "city_code"),
            exDestinationAirport: handleArrayToString(
              exDestinationAirport,
              "city_code"
            ),

            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(
              destinationCountry,
              "countrycode"
            ),
            exOriginCountry: handleArrayToString(
              exOriginCountry,
              "countrycode"
            ),
            exDestinationCountry: handleArrayToString(
              exDestinationCountry,
              "countrycode"
            ),

            codeShareAirline: handleArrayToString(codeShareAirline, "Code"),
            excodeShareAirline: handleArrayToString(excodeShareAirline, "Code"),
            interlineAirline: handleArrayToString(interlineAirline, "Code"),
            exinterlineAirline: handleArrayToString(exinterlineAirline, "Code"),
            connectingAirport: handleArrayToString(
              connectingAirport,
              "city_code"
            ),
            exconnectingAirport: handleArrayToString(
              exconnectingAirport,
              "city_code"
            ),

            tourCode: tourCode === undefined ? "" : tourCode,
            paxType: handleArrayToString(paxType, "raw"),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          handleSnackbarToggle("Updated Successfully", "success");
          setShowLoader(false);
        })
        .catch((err) => {
          handleSnackbarToggle(err.response.data.title, "error");
          setShowLoader(false);
        });
    } else {
      setShowLoader(true);
      await axios
        .post(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/SupplierCommissionBlock",
          JSON.stringify({
            id: 0,
            isActive: String(isActive).toLowerCase() === "true" ? true : false,
            createdBy: "",
            created: moment().format(),
            modifiedBy: "",
            modified: moment().format(),
            supplierCommissionGroupId:
              supplierCommissionGroupId === undefined
                ? groupId
                : supplierCommissionGroupId,
            supplierName: pccCode === undefined ? "" : pccCode.split(",")[1],
            pccCode: pccCode === undefined ? "" : pccCode.split(",")[0],

            travelType: handleArrayToString(travelType, "raw"),
            cabinClass: handleArrayToString(cabinClass, "value"),
            airline: handleArrayToString(airline, "Code"),
            fareType: handleArrayToString(fareType, "raw"),

            rbd: rbd === undefined ? "" : rbd,
            exRBD: exRBD === undefined ? "" : exRBD,
            flightNumbers: flightNumbers === undefined ? "" : flightNumbers,
            exFlightNumbers:
              exFlightNumbers === undefined ? "" : exFlightNumbers,
            bookingDateFrom: inputbookingDateFrom
              ? moment(inputbookingDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            bookingDateTo: inputbookingDateTo
              ? moment(inputbookingDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            exBookingDateFrom: inputExBookingDateFrom
              ? moment(inputExBookingDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            exBookingDateTo: inputexBookingDateTo
              ? moment(inputexBookingDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            travelDateFrom: inputtravelDateFrom
              ? moment(inputtravelDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            travelDateTo: inputtravelDateTo
              ? moment(inputtravelDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            blockoutDateFrom: inputblockOutDateFrom
              ? moment(inputblockOutDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            blockoutDateTo: inputblockOutDateTo
              ? moment(inputblockOutDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            outboundDateFrom: inputoutboundDateFrom
              ? moment(inputoutboundDateFrom).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            outboundDateTo: inputoutboundDateTo
              ? moment(inputoutboundDateTo).set({
                hour: 10,
                minute: 30,
                second: 0,
                millisecond: 0,
              })
              : null,
            fareBasisType:
              fareBasisType === undefined || fareBasisType === ""
                ? "0"
                : fareBasisType,
            fareBasis: fareBasis === undefined ? "" : fareBasis,
            exFareBasisType:
              exFareBasisType === undefined || exFareBasisType === ""
                ? "0"
                : exFareBasisType,
            exFareBasis: exFareBasis === undefined ? "" : exFareBasis,
            iata: iata === undefined || iata === "" ? "0" : iata,
            plb: plb === undefined || plb === "" ? "0" : plb,
            markup: markup === undefined || markup === "" ? "0" : markup,
            dropnet: dropnet === undefined || dropnet === "" ? "0" : dropnet,
            vendorDropnet:
              vendorDropnet === undefined || vendorDropnet === ""
                ? "0"
                : vendorDropnet,
            taxType: taxType === undefined || taxType === "" ? "0" : taxType,
            vendortaxType:
              vendortaxType === undefined || vendortaxType === ""
                ? "0"
                : vendortaxType,
            taxIATA: taxIATA === undefined || taxIATA === "" ? "0" : taxIATA,
            taxPLB: taxPLB === undefined || taxPLB === "" ? "0" : taxPLB,
            vendorIATA:
              vendorIATA === undefined || vendorIATA === "" ? "0" : vendorIATA,
            vendorPLB:
              vendorPLB === undefined || vendorPLB === "" ? "0" : vendorPLB,
            vendorTaxIATA:
              vendorTaxIATA === undefined || vendorTaxIATA === ""
                ? "0"
                : vendorTaxIATA,
            vendorTaxPLB:
              vendorTaxPLB === undefined || vendorTaxPLB === ""
                ? "0"
                : vendorTaxPLB,
            iataType: iataType === undefined ? "" : iataType,
            taxiataType: taxiataType === undefined ? "" : taxiataType,
            vendorIATAType: vendorIATAType === undefined ? "" : vendorIATAType,
            vendortaxIATAType:
              vendortaxIATAType === undefined ? "" : vendortaxIATAType,
            plbType: plbType === undefined ? "" : plbType,
            taxplbType: taxplbType === undefined ? "" : taxplbType,
            vendorPLBType: vendorPLBType === undefined ? "" : vendorPLBType,
            vendortaxPLBType:
              vendortaxPLBType === undefined ? "" : vendortaxPLBType,

            originAirport: handleArrayToString(originAirport, "city_code"),
            destinationAirport: handleArrayToString(
              destinationAirport,
              "city_code"
            ),
            exOriginAirport: handleArrayToString(exOriginAirport, "city_code"),
            exDestinationAirport: handleArrayToString(
              exDestinationAirport,
              "city_code"
            ),

            originCountry: handleArrayToString(originCountry, "countrycode"),
            destinationCountry: handleArrayToString(
              destinationCountry,
              "countrycode"
            ),
            exOriginCountry: handleArrayToString(
              exOriginCountry,
              "countrycode"
            ),
            exDestinationCountry: handleArrayToString(
              exDestinationCountry,
              "countrycode"
            ),

            codeShareAirline: handleArrayToString(codeShareAirline, "Code"),
            excodeShareAirline: handleArrayToString(excodeShareAirline, "Code"),
            interlineAirline: handleArrayToString(interlineAirline, "Code"),
            exinterlineAirline: handleArrayToString(exinterlineAirline, "Code"),
            connectingAirport: handleArrayToString(
              connectingAirport,
              "city_code"
            ),
            exconnectingAirport: handleArrayToString(
              exconnectingAirport,
              "city_code"
            ),

            tourCode: tourCode === undefined ? "" : tourCode,
            paxType: handleArrayToString(paxType, "raw"),
          }),
          {
            headers,
          }
        )
        .then((res) => {
          // console.log(res);
          handleSnackbarToggle("Created Successfully", "success");
          setShowLoader(false);
        })
        .catch((err) => {
          handleSnackbarToggle(err.response.title, "error");
          setShowLoader(false);
        });
    }
  }

  const handleCopyFrom = (val) => {
    try {
      val === null
        ? setCopyFrom("")
        : setCopyFrom(val.supplierCommissionBlocks);
    } catch (err) {
      console.log(err);
      setCopyFrom("");
    }
  };
  const dateFormatter = (str) => {
    return str;
  };
  const closeDrawer = () => {
    if (action === "edit" || action === "view") {
      resetAllTheStates();
      toggleDrawer(false);
    } else {
      resetAllTheStates();
      toggleDrawer(false);
      setCopyFrom("");
    }
  };

  return (
    <div className="ticket-drawer-container mx-width-drawer">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={() => closeDrawer()}
        ></CloseIcon>
      </div>
      <div className="supplier-heading-container supplier-title-flex-container">
        <div>Supplier Commission Block</div>
        {action === "view" && (
          <div className="supplier-heading-icons">
            <IconButton fontSize="small">
              <EditIcon
                fontSize="small"
                className="icon-blue"
                onClick={() => setAction("edit")}
              ></EditIcon>
            </IconButton>
          </div>
        )}
      </div>

      {action === "new" && (
        <div>
          {commissionBlocks ? (
            <div className="supplier-heading-container">
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1 copy-selector">
                <Autocomplete
                  id="copy from select"
                  value={
                    commissionBlocks !== undefined &&
                      commissionBlocks.length > 0
                      ? commissionBlocks.find(
                        (v) =>
                          v.supplierCommissionBlocks.pccCode ===
                          copyFrom?.pccCode
                      ) || ""
                      : ""
                  }
                  onChange={(event, newValue) => handleCopyFrom(newValue)}
                  options={
                    commissionBlocks !== undefined &&
                      commissionBlocks.length > 0
                      ? commissionBlocks
                      : ""
                  }
                  autoHighlight
                  getOptionLabel={(option) =>
                    option &&
                    option.supplierCommissionBlocks.supplierName +
                    " - " +
                    option.supplierCommissionBlocks.pccCode +
                    " - " +
                    option.supplierCommissionBlocks.airline
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Copy From"
                      value={copyFrom?.pccCode}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}

      <ValidatorForm
        onError={(errors) => console.log(errors)}
        onSubmit={handleUpdate}
      >
        <div className="supplier-view-edit-drawer-container">
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="SupplierCommissionGroup"
                  size="small"
                  color="secondary"
                  value={name}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="supplier select"
                  value={pccList.find((v) => v.value === pccCode) || null}
                  onChange={(event, newValue) => {
                    setpccCode(newValue ? newValue.value : "");
                  }}
                  options={pccList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Pseudo Code"
                      value={pccCode}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  required
                  id="traveltype select"
                  multiple
                  value={travelType && travelType}
                  onChange={(event, value) => {
                    settravelType(value);
                  }}
                  options={TripTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Travel Type"
                      value={travelType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: travelType && travelType.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  SupplierCommissionGroup
                </div>
                <div className="view-supplier-drawer-description ">{name}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Pseudo Code</div>
                <div className="view-supplier-drawer-description ">
                  {pccList
                    .filter((id) => id.value === pccCode)
                    .map(({ label }) => label)}
                  {/* {pccCode} */}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer  config-input-flex-1">
                <div className="view-search-drawer-title">Travel Type</div>
                <div className="view-search-drawer-description">
                  {travelType !== undefined &&
                    travelType.length > 0 &&
                    travelType.map((trip) => <p>{trip}</p>)}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="cabin select"
                  multiple
                  value={cabinClass && cabinClass}
                  onChange={(event, value) => {
                    setcabinClass(value);
                  }}
                  options={cabinClassList}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Cabin Class"
                      value={cabinClass}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: cabinClass && cabinClass.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="faretype select"
                  multiple
                  value={fareType && fareType}
                  onChange={(event, value) => {
                    setfareType(value);
                  }}
                  options={fareTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Fare Type"
                      value={fareType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: fareType && fareType.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="airline select"
                  multiple
                  value={airline && airline}
                  onChange={(event, value) => {
                    setairline(value);
                  }}
                  options={extendedAirlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option?.Code === "All"
                      ? "All"
                      : option?.Name + " (" + option?.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Airline"
                      value={airline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        required: airline && airline.length === 0,
                      }}
                      required={true}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">cabin Class</div>
                <div className="view-search-drawer-description">
                  {cabinClass !== undefined &&
                    cabinClass.length > 0 &&
                    cabinClass.map((cabin) => <p>{cabin.label}</p>)}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Fare Type</div>
                <div className="view-search-drawer-description">
                  {fareType !== undefined &&
                    fareType.length > 0 &&
                    fareType.map((trip) => <p>{trip}</p>)}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Airline</div>
                <div className="view-search-drawer-description">
                  {airline !== undefined &&
                    airline.length > 0 &&
                    airline.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="RBD"
                  size="small"
                  color="secondary"
                  value={rbd}
                  onChange={(event) => setrbd(event.target.value)}
                  required
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Exclude-RBD"
                  size="small"
                  color="secondary"
                  value={exRBD}
                  onChange={(event) => setexRBD(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="status-select"
                  value={statusList.find((v) => v === isActive) || null}
                  onChange={(event, newValue) => {
                    setisActive(newValue ? newValue : "");
                  }}
                  options={statusList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Status"
                      value={isActive}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      required
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">RBD</div>
                <div className="view-supplier-drawer-description ">{rbd}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Exclude-RBD</div>
                <div className="view-supplier-drawer-description">{exRBD}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer  config-input-flex-1">
                <div className="view-supplier-drawer-title">Status</div>
                <div className="view-supplier-drawer-description">
                  {isActive.toString()}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div
                className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1"
                style={{ maxWidth: "307px" }}
              >
                <TextValidator
                  variant="outlined"
                  label="Tour Code"
                  size="small"
                  color="secondary"
                  value={tourCode}
                  onChange={(event) => settourCode(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Tour Code</div>
                <div className="view-supplier-drawer-description">
                  {tourCode}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>Conditions</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="bookingdatefrom"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="BookingDate From"
                    format="dd/MM/yyyy"
                    size="small"
                    value={bookingDateFrom ? bookingDateFrom : null}
                    inputValue={bookingDateFrom ? bookingDateFrom : null}
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setbookingDateFrom(date ? formatDate(date) : null);
                      setInputbookingDateFrom(date ? date : null);
                    }}
                    required
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="bookingdateto"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="BookingDate To"
                    format="dd/MM/yyyy"
                    size="small"
                    value={bookingDateTo !== undefined ? bookingDateTo : null}
                    inputValue={
                      bookingDateTo !== undefined ? bookingDateTo : null
                    }
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setbookingDateTo(date ? formatDate(date) : null);
                      setInputbookingDateTo(date ? date : null);
                    }}
                    required
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  {/* <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}> */}
                  <KeyboardDatePicker
                    id="exbookingdatefrom"
                    className="mt-0"
                    autoOk={true}
                    showTodayButton={true}
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Exclude-BookingDate From"
                    format="dd/mm/yyyy"
                    size="small"
                    value={
                      exBookingDateFrom !== undefined ? exBookingDateFrom : null
                    }
                    inputValue={
                      exBookingDateFrom !== undefined ? exBookingDateFrom : null
                    }
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setexBookingDateFrom(date ? formatDate(date) : null);
                      setInputExBookingDateFrom(date ? date : null);
                    }}
                  />
                  {/* </MuiPickersUtilsProvider> */}
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="exbookingdateto"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Exclude-BookingDate To"
                    format="dd/MM/yyyy"
                    size="small"
                    value={
                      exBookingDateTo !== undefined ? exBookingDateTo : null
                    }
                    inputValue={
                      exBookingDateTo !== undefined ? exBookingDateTo : null
                    }
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      setexBookingDateTo(date ? formatDate(date) : null);
                      setInputexBookingDateTo(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  BookingDate From
                </div>
                <div className="view-supplier-drawer-description">
                  {bookingDateFrom}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">BookingDate To</div>
                <div className="view-supplier-drawer-description">
                  {bookingDateTo}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                Exclude-BookingDate From
                </div>
                <div className="view-supplier-drawer-description ">
                  {exBookingDateFrom}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                Exclude-BookingDate To
                </div>
                <div className="view-supplier-drawer-description ">
                  {exBookingDateTo}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="TravelDatefrom"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="TravelDate From"
                    format="dd/MM/yyyy"
                    size="small"
                    value={travelDateFrom !== undefined ? travelDateFrom : null}
                    inputValue={
                      travelDateFrom !== undefined ? travelDateFrom : null
                    }
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    onChange={(date) => {
                      settravelDateFrom(date ? formatDate(date) : null);
                      setInputtravelDateFrom(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="TravelDateto"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="TravelDate To"
                    format="dd/MM/yyyy"
                    size="small"
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    value={travelDateTo !== undefined ? travelDateTo : null}
                    inputValue={
                      travelDateTo !== undefined ? travelDateTo : null
                    }
                    onChange={(date) => {
                      settravelDateTo(date ? formatDate(date) : null);
                      setInputtravelDateTo(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="outboundDatefrom"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Outbound From"
                    format="dd/MM/yyyy"
                    size="small"
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    value={
                      outboundDateFrom !== undefined ? outboundDateFrom : null
                    }
                    inputValue={
                      outboundDateFrom !== undefined ? outboundDateFrom : null
                    }
                    onChange={(date) => {
                      setoutboundDateFrom(date ? formatDate(date) : null);
                      setInputoutboundDateFrom(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="outboundDateto"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Outbound To"
                    format="dd/MM/yyyy"
                    size="small"
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    value={outboundDateTo !== undefined ? outboundDateTo : null}
                    inputValue={
                      outboundDateTo !== undefined ? outboundDateTo : null
                    }
                    onChange={(date) => {
                      setoutboundDateTo(date ? formatDate(date) : null);
                      setInputoutboundDateTo(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  TravelDate From
                </div>
                <div className="view-supplier-drawer-description">
                  {travelDateFrom}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">TravelDate To</div>
                <div className="view-supplier-drawer-description">
                  {travelDateTo}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Outbound From</div>
                <div className="view-supplier-drawer-description ">
                  {outboundDateFrom}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Outbound To</div>
                <div className="view-supplier-drawer-description ">
                  {outboundDateTo}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="blockoutDateFrom"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Blockout From"
                    format="dd/MM/yyyy"
                    size="small"
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    value={
                      blockoutDateFrom !== undefined ? blockoutDateFrom : null
                    }
                    inputValue={
                      blockoutDateFrom !== undefined ? blockoutDateFrom : null
                    }
                    onChange={(date) => {
                      setblockoutDateFrom(date ? formatDate(date) : null);
                      setInputblockOutDateFrom(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Fragment>
                  <KeyboardDatePicker
                    id="blockOutDateto"
                    className="mt-0"
                    autoOk
                    margin="normal"
                    variant="inline"
                    inputVariant="outlined"
                    label="Blockout To"
                    format="dd/MM/yyyy"
                    size="small"
                    InputAdornmentProps={{
                      position: "start",
                      color: "primary",
                    }}
                    value={blockoutDateTo !== undefined ? blockoutDateTo : null}
                    inputValue={
                      blockoutDateTo !== undefined ? blockoutDateTo : null
                    }
                    onChange={(date) => {
                      setblockoutDateTo(date ? formatDate(date) : null);
                      setInputblockOutDateTo(date ? date : null);
                    }}
                  />
                </Fragment>
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Flight Numbers"
                  size="small"
                  color="secondary"
                  value={flightNumbers}
                  onChange={(event) => setflightNumbers(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Exclude-FlightNumbers"
                  size="small"
                  color="secondary"
                  value={exFlightNumbers}
                  onChange={(event) => setexFlightNumbers(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Blockout From</div>
                <div className="view-supplier-drawer-description ">
                  {blockoutDateFrom}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Blockout To</div>
                <div className="view-supplier-drawer-description ">
                  {blockoutDateTo}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Flight Numbers</div>
                <div className="view-supplier-drawer-description">
                  {flightNumbers}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                Exclude-FlightNumbers
                </div>
                <div className="view-supplier-drawer-description ">
                  {exFlightNumbers}
                </div>
              </div>
            )}
          </div>
          {/* <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">

              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">

              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">

              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">

              </div>
            )}
          </div> */}

          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>FareBasis</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="farebasistype select"
                  //multiple
                  //value={fareBasisType && fareBasisType}
                  //onChange={(event, value) => { setfareBasisType(value); }}
                  //options={fareBasisTypeList}
                  //autoHighlight
                  //getOptionLabel={(option) => option}
                  value={
                    fareBasisTypeList.find((v) => v === fareBasisType) || null
                  }
                  onChange={(event, newValue) => {
                    setfareBasisType(newValue ? newValue : "");
                  }}
                  options={fareBasisTypeList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="FareBasis Type"
                      value={fareBasisType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="FareBasis"
                  size="small"
                  color="secondary"
                  value={fareBasis}
                  onChange={(event) => setfareBasis(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">FareBasisType</div>
                <div className="view-supplier-drawer-description">
                  {fareBasisType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">FareBasis</div>
                <div className="view-supplier-drawer-description ">
                  {fareBasis}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="exfarebasistype select"
                  //multiple
                  //value={exFareBasisType && exFareBasisType}
                  //onChange={(event, value) => { setexFareBasisType(value); }}
                  //options={fareBasisTypeList}
                  //autoHighlight
                  //getOptionLabel={(option) => option}
                  value={
                    fareBasisTypeList.find((v) => v === exFareBasisType) || null
                  }
                  onChange={(event, newValue) => {
                    setexFareBasisType(newValue ? newValue : "");
                  }}
                  options={fareBasisTypeList}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-FareBasis Type"
                      value={exFareBasisType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Exclude-FareBasis"
                  size="small"
                  color="secondary"
                  value={exFareBasis}
                  onChange={(event) => setexFareBasis(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                Exclude-FareBasis Type
                </div>
                <div className="view-supplier-drawer-description">
                  {exFareBasisType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Exclude-FareBasis</div>
                <div className="view-supplier-drawer-description ">
                  {exFareBasis}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>Connecting and CodeShare</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>

          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="codeShareAirline select"
                  multiple
                  value={codeShareAirline && codeShareAirline}
                  onChange={(event, value) => {
                    setcodeShareAirline(value);
                  }}
                  options={extendedAirlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="CodeShare Airline"
                      value={codeShareAirline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="excodeShareAirline select"
                  multiple
                  value={excodeShareAirline && excodeShareAirline}
                  onChange={(event, value) => {
                    setexcodeShareAirline(value);
                  }}
                  options={extendedAirlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-CodeShare Airline"
                      value={excodeShareAirline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">
                  CodeShare Airline
                </div>
                <div className="view-search-drawer-description">
                  {codeShareAirline !== undefined &&
                    codeShareAirline.length > 0 &&
                    codeShareAirline.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Exclude-CodeShare Airline</div>
                <div className="view-search-drawer-description">
                  {excodeShareAirline !== undefined &&
                    excodeShareAirline.length > 0 &&
                    excodeShareAirline.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="Interlineairline select"
                  multiple
                  value={interlineAirline && interlineAirline}
                  onChange={(event, value) => {
                    setinterlineAirline(value);
                  }}
                  options={extendedAirlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Interline Airline"
                      value={interlineAirline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exinterline airline select"
                  multiple
                  value={exinterlineAirline && exinterlineAirline}
                  onChange={(event, value) => {
                    setexinterlineAirline(value);
                  }}
                  options={extendedAirlineDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.Code === "All"
                      ? "All"
                      : option.Name + " (" + option.Code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Interline Airline"
                      value={exinterlineAirline}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">
                  Interline Airline
                </div>
                <div className="view-search-drawer-description">
                  {interlineAirline !== undefined &&
                    interlineAirline.length > 0 &&
                    interlineAirline.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Exclude-Interline Airline</div>
                <div className="view-search-drawer-description">
                  {exinterlineAirline !== undefined &&
                    exinterlineAirline.length > 0 &&
                    exinterlineAirline.map((air) => (
                      <p>{air != undefined ? air.Code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>Commission</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="paxtype select"
                  multiple
                  value={paxType && paxType}
                  onChange={(event, value) => {
                    setpaxType(value);
                  }}
                  options={paxTypeList}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Pax Type"
                      value={paxType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1"></div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Pax Type</div>
                <div className="view-search-drawer-description">
                  {paxType !== undefined &&
                    paxType.length > 0 &&
                    paxType.map((pax) => pax).join(", ")}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1"></div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">Client</div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="IATA"
                  size="small"
                  color="secondary"
                  value={iata}
                  // validators={['required', 'minNumber:0', 'maxNumber:100', `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`]}
                  // errorMessages={['required', 'Min value is 0', 'Max value is 100', "Max value is 100 | Only 2 digits after decimal is allowed"]}
                  onChange={(event) => setiata(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="iatatype"
                  value={calTypes.find((v) => v === iataType) || null}
                  onChange={(event, newValue) => {
                    setiataType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="IATA Type"
                      value={iataType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="PLB"
                  size="small"
                  color="secondary"
                  value={plb}
                  // validators={['required', 'minNumber:0', 'maxNumber:100', `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`]}
                  // errorMessages={['required', 'Min value is 0', 'Max value is 100', "Max value is 100 | Only 2 digits after decimal is allowed"]}
                  onChange={(event) => setplb(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="plbtype"
                  value={calTypes.find((v) => v === plbType) || null}
                  onChange={(event, newValue) => {
                    setplbType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="PLB Type"
                      value={plbType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Dropnet"
                  size="small"
                  color="secondary"
                  value={dropnet}
                  validators={[
                    "minNumber:0",
                    "maxNumber:10000",
                    `matchRegexp:^\\d{0,4}\.?\\d{0,2}$`,
                  ]}
                  errorMessages={[
                    "Min value is 0",
                    "Max value is 10000",
                    "Max value is 10000 | Only 2 digits after decimal is allowed",
                  ]}
                  onChange={(event) => setdropnet(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">IATA</div>
                <div className="view-supplier-drawer-description">{iata}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">IATA Type</div>
                <div className="view-supplier-drawer-description ">
                  {iataType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">PLB</div>
                <div className="view-supplier-drawer-description ">{plb}</div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">PLB Type</div>
                <div className="view-supplier-drawer-description ">
                  {plbType}
                </div>
              </div>
            )}

            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Dropnet</div>
                <div className="view-supplier-drawer-description">
                  {dropnet}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Tax IATA"
                  size="small"
                  color="secondary"
                  value={taxIATA}
                  // validators={['minNumber:0', 'maxNumber:100', `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`]}
                  // errorMessages={['Min value is 0', 'Max value is 100', "Max value is 100 | Only 2 digits after decimal is allowed"]}
                  onChange={(event) => settaxIATA(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="taxiataType"
                  value={calTypes.find((v) => v === taxiataType) || null}
                  onChange={(event, newValue) => {
                    settaxiataType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Tax IATA Type"
                      value={taxiataType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Tax PLB"
                  size="small"
                  color="secondary"
                  value={taxPLB}
                  // validators={['minNumber:0', 'maxNumber:100', `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`]}
                  // errorMessages={['Min value is 0', 'Max value is 100', "Max value is 100 | Only 2 digits after decimal is allowed"]}
                  onChange={(event) => settaxPLB(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="taxplbType"
                  value={calTypes.find((v) => v === taxplbType) || null}
                  onChange={(event, newValue) => {
                    settaxplbType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Tax PLB Type"
                      value={taxplbType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="taxType select"
                  value={taxCategory.find((v) => v === taxType) || null}
                  onChange={(event, newValue) => {
                    settaxType(newValue ? newValue : null);
                  }}
                  options={taxCategory}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Tax Type"
                      value={taxType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Tax IATA</div>
                <div className="view-supplier-drawer-description">
                  {taxIATA}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Tax IATA Type</div>
                <div className="view-supplier-drawer-description ">
                  {taxiataType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Tax PLB</div>
                <div className="view-supplier-drawer-description ">
                  {taxPLB}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Tax PLB Type</div>
                <div className="view-supplier-drawer-description ">
                  {taxplbType}
                </div>
              </div>
            )}

            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Tax Type</div>
                <div className="view-supplier-drawer-description ">
                  {taxType}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">Vendor</div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Vendor IATA"
                  size="small"
                  color="secondary"
                  value={vendorIATA}
                  // validators={['required', 'minNumber:0', 'maxNumber:100', `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`]}
                  // errorMessages={['required', 'Min value is 0', 'Max value is 100', "Max value is 100 | Only 2 digits after decimal is allowed"]}
                  onChange={(event) => setvendorIATA(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="vendorIATAType"
                  value={calTypes.find((v) => v === vendorIATAType) || null}
                  onChange={(event, newValue) => {
                    setvendorIATAType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor IATA Type"
                      value={vendorIATAType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Vendor PLB"
                  size="small"
                  color="secondary"
                  value={vendorPLB}
                  // validators={['required', 'minNumber:0', 'maxNumber:100', `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`]}
                  // errorMessages={['required', 'Min value is 0', 'Max value is 100', "Max value is 100 | Only 2 digits after decimal is allowed"]}
                  onChange={(event) => setvendorPLB(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="vendorPLBType"
                  value={calTypes.find((v) => v === vendorPLBType) || null}
                  onChange={(event, newValue) => {
                    setvendorPLBType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor PLB Type"
                      value={vendorPLBType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Vendor Dropnet"
                  size="small"
                  color="secondary"
                  value={vendorDropnet}
                  validators={[
                    "minNumber:0",
                    "maxNumber:10000",
                    `matchRegexp:^\\d{0,4}\.?\\d{0,2}$`,
                  ]}
                  errorMessages={[
                    "Min value is 0",
                    "Max value is 10000",
                    "Max value is 10000 | Only 2 digits after decimal is allowed",
                  ]}
                  onChange={(event) => setvendorDropnet(event.target.value)}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Vendor IATA</div>
                <div className="view-supplier-drawer-description">
                  {vendorIATA}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Vendor IATA Type
                </div>
                <div className="view-supplier-drawer-description">
                  {vendorIATAType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Vendor PLB</div>
                <div className="view-supplier-drawer-description">
                  {vendorPLB}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Vendor PLB Type
                </div>
                <div className="view-supplier-drawer-description">
                  {vendorPLBType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">Vendor Dropnet</div>
                <div className="view-supplier-drawer-description ">
                  {vendorDropnet}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Vendor TaxIATA"
                  size="small"
                  color="secondary"
                  value={vendorTaxIATA}
                  validators={[
                    "minNumber:0",
                    "maxNumber:100",
                    `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`,
                  ]}
                  errorMessages={[
                    "Min value is 0",
                    "Max value is 100",
                    "Max value is 100 | Only 2 digits after decimal is allowed",
                  ]}
                  onChange={(event) => setvendorTaxIATA(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="vendortaxIATAType"
                  value={calTypes.find((v) => v === vendortaxIATAType) || null}
                  onChange={(event, newValue) => {
                    setvendortaxIATAType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor TaxIATA Type"
                      value={vendortaxIATAType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <TextValidator
                  variant="outlined"
                  label="Vendor TaxPLB"
                  size="small"
                  color="secondary"
                  value={vendorTaxPLB}
                  validators={[
                    "minNumber:0",
                    "maxNumber:100",
                    `matchRegexp:^\\d{0,2}\.?\\d{0,2}$`,
                  ]}
                  errorMessages={[
                    "Min value is 0",
                    "Max value is 100",
                    "Max value is 100 | Only 2 digits after decimal is allowed",
                  ]}
                  onChange={(event) => setvendorTaxPLB(event.target.value)}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="vendortaxPLBType"
                  value={calTypes.find((v) => v === vendortaxPLBType) || null}
                  onChange={(event, newValue) => {
                    setvendortaxPLBType(newValue ? newValue : "");
                  }}
                  options={calTypes}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor TaxPLB Type"
                      value={vendortaxPLBType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="supplier-view-edit-drawer-input config-input-flex-1">
                <Autocomplete
                  id="vendortaxType"
                  value={taxCategory.find((v) => v === vendortaxType) || null}
                  onChange={(event, newValue) => {
                    setvendortaxType(newValue ? newValue : null);
                  }}
                  options={taxCategory}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Vendor Tax Type"
                      value={vendortaxType}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Vendor TaxIATA</div>
                <div className="view-supplier-drawer-description">
                  {vendorTaxIATA}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Vendor TaxIATA Type
                </div>
                <div className="view-supplier-drawer-description ">
                  {vendortaxIATAType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer margin-right-16 config-input-flex-1">
                <div className="view-supplier-drawer-title">Vendor TaxPLB</div>
                <div className="view-supplier-drawer-description ">
                  {vendorTaxPLB}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Vendor TaxPLB Type
                </div>
                <div className="view-supplier-drawer-description">
                  {vendortaxPLBType}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-supplier-drawer config-input-flex-1">
                <div className="view-supplier-drawer-title">
                  Vendor Tax Type
                </div>
                <div className="view-supplier-drawer-description">
                  {vendortaxType}
                </div>
              </div>
            )}
          </div>
          <div className="supplier-view-edit-drawer-if-container">
            <div className="supplier-view-edit-drawer-if-line"></div>
            <div>Region</div>
            <div className="supplier-view-edit-drawer-if-line"></div>
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  required
                  id="originAirport select"
                  multiple
                  value={originAirport && originAirport}
                  onChange={(event, value) => {
                    setoriginAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Airport"
                      value={originAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: originAirport && originAirport.length === 0
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  required
                  id="destinationAirport select"
                  multiple
                  value={destinationAirport && destinationAirport}
                  onChange={(event, value) => {
                    setdestinationAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Airport"
                      value={destinationAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: destinationAirport && destinationAirport.length === 0
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Origin Airport</div>
                <div className="view-search-drawer-description">
                  {originAirport !== undefined &&
                    originAirport.length > 0 &&
                    originAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Destination Airport
                </div>
                <div className="view-search-drawer-description">
                  {destinationAirport !== undefined &&
                    destinationAirport.length > 0 &&
                    destinationAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="exoriginAirport select"
                  multiple
                  value={exOriginAirport && exOriginAirport}
                  onChange={(event, value) => {
                    setexOriginAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Origin Airport"
                      value={exOriginAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exdestinationAirport select"
                  multiple
                  value={exDestinationAirport && exDestinationAirport}
                  onChange={(event, value) => {
                    setexDestinationAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Destination Airport"
                      value={exDestinationAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">
                  Ex-Origin Airport
                </div>
                <div className="view-search-drawer-description">
                  {exOriginAirport !== undefined &&
                    exOriginAirport.length > 0 &&
                    exOriginAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Ex-Destination Airport
                </div>
                <div className="view-search-drawer-description">
                  {exDestinationAirport !== undefined &&
                    exDestinationAirport.length > 0 &&
                    exDestinationAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="origincountry select"
                  multiple
                  value={originCountry && originCountry}
                  onChange={(event, value) => {
                    setoriginCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Origin Country"
                      value={originCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: originCountry && originCountry.length === 0
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="destinationcountry select"
                  multiple
                  value={destinationCountry && destinationCountry}
                  onChange={(event, value) => {
                    setdestinationCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Destination Country"
                      value={destinationCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        //required: destinationCountry && destinationCountry.length === 0
                      }}
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Origin Country</div>
                <div className="view-search-drawer-description">
                  {originCountry !== undefined &&
                    originCountry.length > 0 &&
                    originCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">
                  Destination Country
                </div>
                <div className="view-search-drawer-description">
                  {destinationCountry !== undefined &&
                    destinationCountry.length > 0 &&
                    destinationCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="exorigincountry select"
                  multiple
                  value={exOriginCountry && exOriginCountry}
                  onChange={(event, value) => {
                    setexOriginCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Origin Country"
                      value={exOriginCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exdestinationcountry select"
                  multiple
                  value={exDestinationCountry && exDestinationCountry}
                  onChange={(event, value) => {
                    setexDestinationCountry(value);
                  }}
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.code === "All"
                      ? "All"
                      : option.label + " (" + option.code + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Destination Country"
                      value={exDestinationCountry}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">Exclude-Origin Country</div>
                <div className="view-search-drawer-description">
                  {exOriginCountry !== undefined &&
                    exOriginCountry.length > 0 &&
                    exOriginCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Exclude-Destination Country</div>
                <div className="view-search-drawer-description">
                  {exDestinationCountry !== undefined &&
                    exDestinationCountry.length > 0 &&
                    exDestinationCountry.map((air) => (
                      <p>{air != undefined ? air.code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input margin-right-16 config-input-flex-1">
                <Autocomplete
                  id="connectingAirport select"
                  multiple
                  value={connectingAirport && connectingAirport}
                  onChange={(event, value) => {
                    setconnectingAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Connecting Airport"
                      value={connectingAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action !== "view" && (
              <div className="search-view-edit-drawer-input  config-input-flex-1">
                <Autocomplete
                  id="exconnectingAirport select"
                  multiple
                  value={exconnectingAirport && exconnectingAirport}
                  onChange={(event, value) => {
                    setexconnectingAirport(value);
                  }}
                  options={airportDetails}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.city_code === "All"
                      ? "All"
                      : option.airport_name +
                      "-" +
                      option.city +
                      " (" +
                      option.city_code +
                      ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      label="Exclude-Connecting Airport"
                      value={exconnectingAirport}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  )}
                  style={{ maxWidth: "34vw" }}
                />
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer margin-right-16 config-input-flex-1">
                <div className="view-search-drawer-title">
                  Connecting Airport
                </div>
                <div className="view-search-drawer-description">
                  {connectingAirport !== undefined &&
                    connectingAirport.length > 0 &&
                    connectingAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
            {action === "view" && (
              <div className="view-search-drawer config-input-flex-1">
                <div className="view-search-drawer-title">Exclude-Connecting Airport</div>
                <div className="view-search-drawer-description">
                  {exconnectingAirport !== undefined &&
                    exconnectingAirport.length > 0 &&
                    exconnectingAirport.map((air) => (
                      <p>{air != undefined ? air.city_code : ""}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
          {/* <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">                
                <div className="view-search-drawer-title py-2">Origin Area</div>
                <ReactTreeDropdown
                    value={originAreaSelectedValues}
                    dropdownOptions={RegionWiseArea}  
                    //initializeValue={JSON.parse(JSON.stringify(dummydata))}
                    handleValueChange={(selectedData) => {
                      setOriginAreaSelectedValues(selectedData);

                     
                        let get_Origin_Area_val = selectedData.map((item, i) => (
                         item.id+'|'+item.value
                        )).join(', ');

                        setoriginArea(get_Origin_Area_val);
                        console.log(selectedData)
                        console.log("Origin Area: "+ get_Origin_Area_val)
                        //console.log("Origin Area: "+ get_Origin_Areatree_val)
                      
                    }}
                    optionStyle={OPTION_STYLE.VERTICAL}
                    placeholder="Select Origin Area"
                />
              </div>
            )}
          </div> */}
          {/* <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">                
              <div className="view-search-drawer-title py-2">Destination Area</div>
              <ReactTreeDropdown
                  value={destinationAreaSelectedValues}
                  dropdownOptions={RegionWiseArea}  
                  //initializeValue={JSON.parse(JSON.stringify(dummydata))}
                  handleValueChange={(selectedData) => {
                    setDestinationAreaSelectedValues(selectedData);

                   
                      let get_Destination_Area_val = selectedData.map((item, i) => (
                       item.id+'|'+item.value
                      )).join(', ');

                      setdestinationArea(get_Destination_Area_val);
                      console.log(selectedData)
                      console.log("Destination Area: "+ get_Destination_Area_val)
                    
                  }}
                  optionStyle={OPTION_STYLE.VERTICAL}
                  placeholder="Select Destination Area"
              />
            </div>            
            )}              
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">                
                <div className="view-search-drawer-title py-2">Ex Origin Area</div>
                <ReactTreeDropdown
                    value={exOriginAreaSelectedValues}
                    dropdownOptions={RegionWiseArea}  
                    //initializeValue={JSON.parse(JSON.stringify(dummydata))}
                    handleValueChange={(selectedData) => {
                      setexOriginAreaSelectedValues(selectedData);

                     
                        let get_exOrigin_Area_val = selectedData.map((item, i) => (
                         item.id+'|'+item.value
                        )).join(', ');

                        setexOriginArea(get_exOrigin_Area_val);
                        console.log(selectedData)
                        console.log("exOrigin Area: "+ get_exOrigin_Area_val)
                        //console.log("Origin Area: "+ get_Origin_Areatree_val)
                      
                    }}
                    optionStyle={OPTION_STYLE.VERTICAL}
                    placeholder="Select Ex Origin Area"
                />
              </div>
            )}
          </div>
          <div className="search-view-edit-drawer-input-container">
            {action !== "view" && (
              <div className="search-view-edit-drawer-input config-input-flex-1">                
              <div className="view-search-drawer-title py-2">Ex Destination Area</div>
              <ReactTreeDropdown
                  value={exDestinationAreaSelectedValues}
                  dropdownOptions={RegionWiseArea}  
                  //initializeValue={JSON.parse(JSON.stringify(dummydata))}
                  handleValueChange={(selectedData) => {
                    setexDestinationAreaSelectedValues(selectedData);

                   
                      let get_exDestination_Area_val = selectedData.map((item, i) => (
                       item.id+'|'+item.value
                      )).join(', ');

                      setexDestinationArea(get_exDestination_Area_val);
                      console.log(selectedData)
                      console.log("exDestination Area: "+ get_exDestination_Area_val)
                    
                  }}
                  optionStyle={OPTION_STYLE.VERTICAL}
                  placeholder="Select Ex Destination Area"
              />
            </div>            
            )}              
          </div> */}
        </div>
        {action !== "view" && (
          <div>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "25px",
                width: "500px",
              }}
              type="submit"
            >
              {action === "edit" ? "Update" : "Create"}
            </Button>
            {showLoader && <RiseLoading Supplier={showLoader} />}
          </div>
        )}
      </ValidatorForm>
    </div>
  );
}
