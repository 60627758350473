import React, { useEffect, useState } from "react";
import { checkIfUserStaff } from "../../services/AuthService";
import ViewCancellation from "./ViewCancellation.css";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import RiseLoading from "../Loader/RiseLoader";
import Button from "@mui/joy/Button";
import findAirlinebyCode from "../../functions/findAirlineByCode";
import findAirportbyCode from "../../functions/findAirportByCode";
import getAirportByCode from "../../functions/getAirportByCode";
import Divider from "@mui/joy/Divider";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@material-ui/core/Grid";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import axios from "axios";
import { BorderBottom } from "@material-ui/icons";
import { useLocation } from "react-router-dom";

const ViewCancellationDetails = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [bookingDetails, setbookingDetails] = useState([]);
  const [historyDetail, setHistoryDetail] = useState([]);
  const [refundConfrimDialogOpen, setRefundConfrimDialogOpen] = useState(false);
  const [totalFare, settotalFare] = useState([]);
  const [onwardFareChange, setOnwardFareChange] = useState([]);
  const [vendorFeeChange, setVendorFeeChange] = useState([]);
  const [cancelchargeData, setCancellationCharge] = useState([]);
  const [supplierservicecharge, setSupplierServiceCharge] = useState([]);
  const [staffremarks, setstaffRemarks] = useState("");
  const [stfcharge, setSTFCharge] = useState([]);
  const [rafcharge, setRAFCharge] = useState([]);
  const [gfServiceFeeChange, setGFServiceFeeChange] = useState([]);
  const [netrefundCharge, setNetRefundCharge] = useState([]);
  const [supplierNetRefundCharge, setSupplierNetRefundCharge] = useState([]);
  const [clientCancellationCharge, setClientCancellationCharge] = useState([]);
  const [clientRefundAmount, setClientRefundAmount] = useState([]);
  const [errorMsgTkt, seterrorMsgTkt] = useState("");
  const [updateProcess, setupdateProcess] = useState(false);
  const [selectedcancelstatus, setCancelSelectedstatus] = useState("None");
  const [currentPassengerID, setCurrentPassengerID] = useState("");
  const [showOnwardFare, setShowOnwardFare] = useState("");
  const [balTicketAmt, setBalTicketAmt] = useState(false);
  const [showOneRow, setShowOneRow] = useState(false);
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [passengersDetails, setPassengersDetails] = useState({});
  const [segGroupsById, setSegGroupsById] = useState({});
  const [segTypeOnward, setSegTypeOnward] = useState(false);
  const [totalClientNetRefund, setTotalClientNetRefund] = useState(false);
  const [pccList, setPccList] = useState([]);
  const location = useLocation();
  const AuthorizedUsers = [
    'hafsa.q@akbaroffshore.com',
    'swapnil@akbaroffshore.com',
    'rupali.j@akbaroffshore.com',
    'shalini@akbaroffshore.com',
    'amit.g@akbaroffshore.com',
    'accounts1@akbaroffshore.com',
    'vijeesh.c@akbaroffshore.com',
    'nitin.d@akbaroffshore.com',
    'jagruti@akbaroffshore.com',
    'accounts.audit@akbaroffshore.com'
  ];
  const user = JSON.parse(localStorage.getItem("user"));
  let OrgPccList = [];

  const handleStatusChange = (event, newValue) => {
    setCancelSelectedstatus(newValue);
  };


  const handleInputChange = (value, passengerId, fieldName) => {

    setPassengersDetails((prevPassengersDetails) => {
      const updatedPassengersDetails = { ...prevPassengersDetails };

      if (!updatedPassengersDetails[passengerId]) {
        updatedPassengersDetails[passengerId] = {
          onwardFare: 0,
          vendorFee: 0,
          cancellationCharge: 0,
          supplierServiceCharge: 0,
          stfCharge: 0,
          rafCharge: 0,
          gfsServiceCharge: 0,
          clientCancel: 0,
          clientGFSService: 0,
          clientNetRefund: 0,
          supplierNetRefund: 0,
          totalFare: 0
        };
      }


      updatedPassengersDetails[passengerId][fieldName] = value == "0" ? 0 : value.replace(/^0+/, '');

      const passengerDetails = updatedPassengersDetails[passengerId];

      const {
        vendorFee,
        cancellationCharge,
        supplierServiceCharge,
        stfCharge,
        rafCharge
      } = passengerDetails;

      const clientCancelValue =
        parseFloat(vendorFee || 0) +
        parseFloat(cancellationCharge || 0) +
        parseFloat(supplierServiceCharge || 0) +
        parseFloat(stfCharge || 0) +
        parseFloat(rafCharge || 0);

      passengerDetails.clientCancel = clientCancelValue;

      if (fieldName === "gfsServiceCharge") {
        passengerDetails.clientGFSService = value == "0" ? 0 : value.replace(/^0+/, '');
      }

      return updatedPassengersDetails;
    });
  };






  const handleNetRefundChange = (passengerId, value) => {
    if (shouldRecalculateNetRefund) {
      calculateNetRefundCharge(passengerId);
    }
  };


  useEffect(() => {
    console.log('useEff passengersDetails', passengersDetails)
    if (bookingDetails &&
      bookingDetails.flight &&
      bookingDetails.flight[0] && bookingDetails?.flight[0]?.cancellationRefundAmounts) {
      calcTotalNetRefund();
    }
  }, [passengersDetails]);

  useEffect(() => {
    calculateTotalFare();
  }, [bookingDetails]);



  const calculateTotalFare = () => {

    if (
      bookingDetails &&
      bookingDetails.flight &&
      bookingDetails.flight[0] &&
      bookingDetails.flight[0].commissionDetails &&
      bookingDetails.flight[0].commissionDetails &&
      bookingDetails.flight[0].commissionDetails.flightFares &&
      bookingDetails.flight[0].passengers
    ) {
      const totalFareResult = bookingDetails.flight[0].passengers
        .filter(
          (passenger) =>
            passenger.flightTicketStatus === "Cancel_Requested" ||
            passenger.flightTicketStatus === "Cancelled" ||
            passenger.flightTicketStatus === "Refund_Initiated" ||
            passenger.flightTicketStatus === "Refunded"
        )
        .reduce((totalFareResult, passenger) => {
          const matchingFare =
            bookingDetails.flight[0].commissionDetails.flightFares.find(
              (paxfare) => paxfare.paxType === passenger.paxType
            );

          const passengerTotalFare = matchingFare
            ? matchingFare.baseFare +
            matchingFare.taxes.reduce(
              (taxTotal, tax) => taxTotal + tax.amt,
              0
            )
            : 0;

          totalFareResult[passenger.id] = passengerTotalFare;
          return totalFareResult;
        }, []);
      settotalFare(totalFareResult);
    }
  };




  const shouldRecalculateNetRefund = true;

  const calculateNetRefundCharge = (passengerId, status) => {

    const passengerData = passengersDetails[passengerId];

    if (!passengerData) {
      return;
    }

    const onwardFareValue = parseFloat(passengerData.onwardFare) || 0;
    const vendorFeeValue = parseFloat(passengerData.vendorFee) || 0;
    const cancellationChargeValue = parseFloat(passengerData.cancellationCharge) || 0;
    const supplierServiceChargeValue = parseFloat(passengerData.supplierServiceCharge) || 0;
    const stfChargeValue = parseFloat(passengerData.stfCharge) || 0;
    const rafChargeValue = parseFloat(passengerData.rafCharge) || 0;
    const gfServiceFeeValue = parseFloat(passengerData.gfsServiceCharge) || 0;
    const totalFare = parseFloat(passengerData.totalFare) || 0;

    const isReturnTrip = bookingDetails?.flight[0]?.cancellationRefundAmounts?.find(amount => {
      return amount.paxId === passengerId && amount.segType === "return";
    });

    const netRefundValue = (
      ((onwardFareValue && onwardFareValue !== '' && isReturnTrip) ? (totalFare - onwardFareValue) : (totalFare)) -
      vendorFeeValue -
      cancellationChargeValue -
      supplierServiceChargeValue -
      stfChargeValue -
      rafChargeValue -
      gfServiceFeeValue
    ).toFixed(2);


    const clientNetRefund = parseFloat(netRefundValue);


    const updatedPassengerDetails = {
      ...passengersDetails,
      [passengerId]: {
        ...passengerData,
        clientNetRefund: clientNetRefund,
        supplierNetRefund: clientNetRefund
      }
    };

    setPassengersDetails(updatedPassengerDetails);
  };




  const processArray = () => {
    let resultArray = [];

    if (
      bookingDetails &&
      bookingDetails.cancelRefNo &&
      bookingDetails.flight &&
      bookingDetails.flight[0] &&
      bookingDetails.flight[0].cancellationRefundAmounts
    ) {
      const cancelRefNo = location.state.refId;

      const passengerArray = Object.entries(passengersDetails).map(([passengerId, details]) => ({
        id: passengerId,
        ...details
      }));

      passengerArray.forEach((psg) => {
        const passengerId = psg.id;

        let supplierfare = {
          cancelRequestId: 0,
          cancelRefId: cancelRefNo,
          paxId: parseInt(passengerId),
          supplierTicketAmount: parseFloat(psg.totalFare || 0),
          OnwardFares: parseFloat(psg.onwardFare || 0),
          vendorFee: parseFloat(psg.vendorFee || 0),
          cancellationCharge: parseFloat(psg.cancellationCharge || 0),
          supplierServiceFee: parseFloat(psg.supplierServiceCharge || 0),
          stf: parseFloat(psg.stfCharge || 0),
          raf: parseFloat(psg.rafCharge || 0),
          gfServiceFee: parseFloat(psg.gfsServiceCharge || 0),
          netRefund: parseFloat(psg.clientNetRefund || 0)
        };


        resultArray.push({ ...supplierfare });

      });
    }

    return resultArray;
  }
    ;


  const cancellationRefundAmountRqs = processArray();

  const calcTotalNetRefund = () => {

    let totalClientNetRefund = 0;

    bookingDetails?.flight[0]?.cancellationRefundAmounts?.map((psg) => {

      const passengerId = psg.paxId;
      const passengerDetails = passengersDetails[passengerId];
      if (passengerDetails) {
        totalClientNetRefund += Number(passengerDetails.clientNetRefund) || 0;
      }

    });
    setTotalClientNetRefund(totalClientNetRefund);
  }
  const updateRefundDetailremarks = async () => {
    seterrorMsgTkt("");
    setupdateProcess(true);
    setShowLoader(true);


    const headers = {
      "Content-Type": "application/json",
    };
    const orderId = bookingDetails.orderRefId || "";
    await axios
      .post(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/CreatePNR/v1/CancellationHistoryFlow",
        JSON.stringify({
          orderRefId: orderId,
          status: selectedcancelstatus,
          remarks: staffremarks,
          cancellationRefundAmountRq: cancellationRefundAmountRqs,
        }),
        {
          headers,
        }
      )
      .then((response) => {
        setupdateProcess(false);
        const clientRefundAmount = response.data.clientRefundAmount || [];

        setClientRefundAmount(clientRefundAmount);
        const orderRefId = window.location.pathname.split("/").pop();
        loadBookingDetails(orderRefId);
        setShowLoader(false);
      })
      .catch((err) => {
        setupdateProcess(false);
        setShowLoader(false);
        //(err.response.data.detail);
        console.log(err);
      });
  };

  const cancel = () => {
    setRefundConfrimDialogOpen(false);
  };
  const refundNow = (event) => {
    setRefundConfrimDialogOpen(true);
  };


  const refundNowConfrim = (event) => {
    updateRefundDetailremarks();
    setRefundConfrimDialogOpen(false);
  };

  const getDuration = (duration) => {
    return `${String(Math.floor(duration / 60)).padStart(2, "0")} h ${String(
      duration % 60
    ).padStart(2, "0")} m`;
  };
  useEffect(() => {
    const orderRefId = window.location.pathname.split("/").pop();

    loadBookingDetails(orderRefId);
    checkIfUserStaff() && loadPCCDetails();
  }, []);


  async function loadBookingDetails(orderRefId) {

    setShowLoader(true);
    setstaffRemarks("");

    let balanceTicketAmt = false;

    try {
      const response = await axios.get(
        process.env.REACT_APP_CORE_URL +
        "Flights/Booking/GetBooking/v1/GetCancelList/" +
        location.state.refId
      );

      let temp = response.data;

      const isReturn = temp.flight[0].cancellationRefundAmounts.some((psg) => (
        psg.segType.toLowerCase().includes("return")
      ));

      if (isReturn) {
        setShowOnwardFare(true);
      } else {
        setShowOnwardFare(false);
      }


      if (
        (temp.flight[0].segGroups[0].segments[0].flightTicketStatus ===
          "Cancel_Requested" ||
          temp.flight[0].segGroups[0].segments[0].flightTicketStatus ===
          "Refund_Initiated" ||
          temp.flight[0].segGroups[0].segments[0].flightTicketStatus ===
          "Refunded") &&
        temp.flight[0]?.segGroups[1]?.segments[0]?.flightTicketStatus ===
        "Refunded"
      ) {
        balanceTicketAmt = true;
        setBalTicketAmt(true);

      } else {
        balanceTicketAmt = false;
        setBalTicketAmt(false);
      }




      temp.flight.map((flight) => {
        flight.flightFares = flight.flightFares.sort((a, b) =>
          a.paxType.localeCompare(b.paxType)
        );
      });
      if (checkIfUserStaff()) {
        setHistoryDetail(response.data.flight[0].flightBookingStatusFlows);
      }

      let passengerDetails = {};
      let totalClientNetRefund = 0;

      temp.flight[0].cancellationRefundAmounts.map((psg) => {

        const passengerId = psg.paxId;

        if (!passengerDetails[passengerId]) {
          passengerDetails[passengerId] = {
            onwardFare: psg.onwardFares || 0,
            vendorFee: psg.vendorFee || 0,
            cancellationCharge: psg.cancellationCharge || 0,
            supplierServiceCharge: psg.supplierServiceFee || 0,
            stfCharge: psg.stf || 0,
            rafCharge: psg.raf || 0,
            gfsServiceCharge: psg.gfServiceFee || 0,
            clientCancel:
              (psg.vendorFee || 0) +
              (psg.cancellationCharge || 0) +
              (psg.supplierServiceFee || 0) +
              (psg.stf || 0) +
              (psg.raf || 0)
            ,
            clientGFSService: psg.clientGFServiceFee || 0,
            clientNetRefund: psg.clientNetRefund || 0,
            supplierNetRefund: psg.netRefund || 0
          };
        }

        let totalFare = 0;


        temp.flight[0]?.flightFares?.map((fare) => {
          if (fare.paxType === psg.paxType) {

            totalFare += fare.baseFare;


            fare?.taxes?.map((tax) => {
              totalFare += tax.amt;
            });


            temp.flight[0]?.baggages?.map((baggage) => {
              if (baggage.paxType === psg.paxType && baggage.paxName === psg.paxName) {
                totalFare += baggage.amount;
              }
            });


            temp.flight[0]?.meal?.map((meal) => {
              if (meal.paxType === psg.paxType && meal.paxName === psg.paxName) {
                totalFare += meal.amount;
              }
            });


            temp.flight[0]?.seat?.map((seat) => {
              if (seat.paxType === psg.paxType && seat.paxName === psg.paxName) {
                totalFare += seat.amount;
              }
            });

          }
        });


        passengerDetails[passengerId].totalFare = psg.segType === "onward" ? psg.onwardFares : totalFare


        const allSegTypeOnward = (psg.segType !== 'onward' && psg.segType !== 'oneWay' && psg.segType !== 'both') ? false : true
        setSegTypeOnward(allSegTypeOnward)

        totalClientNetRefund += Number(psg.clientNetRefund) || 0;
        setTotalClientNetRefund(totalClientNetRefund);

      });



      console.log('passengerDetails', passengerDetails);
      setPassengersDetails(passengerDetails);



      console.log('temp', temp)
      setbookingDetails(() => temp);
      setShowLoader(false);


    } catch (err) {
      setShowLoader(false);
      console.error(err);
    }
  }

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            if (
              bookingDetails &&
              bookingDetails.flights &&
              bookingDetails.flights[0]
            ) {
              OrgPccList = response.data.map((item) => {
                return item.pccConfigs;
              });
              if (OrgPccList.length > 0) {
                let tmp = OrgPccList.find(
                  (item) => item.pccCode === bookingDetails?.flights[0]?.pcc
                );

              }
            }
            for (var i = 0; i < response.data.length; i++) {
              let k = i;
              if (
                vendorArray.some(
                  (item) => item.id === response.data[k].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                });
              } else {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label: response.data[i].pccConfigs.pccCode,
                });
              }
            }
            setPccList(tmpArray);
          });
      });
  }


  return (
    <div>
      <React.Fragment>
        <Modal
          open={refundConfrimDialogOpen}
          onClose={() => {
            setRefundConfrimDialogOpen(false);
          }}
        >
          <ModalDialog
            variant="outlined"
            role="alertdialog"
            className="textCenter pad1vw"
          >
            <ModalClose variant="plain" sx={{ m: 0 }} />
            <Divider />
            <DialogContent>
              <span className="colorBlack">
                <h5>Would You Like to Proceed ?</h5>
                <h5>Please reverify the updated value, status and don't forget to click on calculate button to get correct value to refund</h5>
              </span>
            </DialogContent>
            <div className="double-btn">
              <div>
                <Button
                  variant="contained"
                  className="cancelref-btn"
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  className="refund-btn"
                  onClick={refundNowConfrim}
                >
                  Submit
                </Button>
              </div>
            </div>
          </ModalDialog>
        </Modal>
      </React.Fragment>
      <div>
        {showLoader ? (
          <RiseLoading isviewBooking={showLoader} />
        ) : (
          <>
            <Grid container className="viewBooking">
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                {bookingDetails && bookingDetails?.flight && bookingDetails?.flight[0] &&
                  <div className="itenary-container-header">
                    <div className="row mb-3 mt-3" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
                      <div className="col-6">Flight Itinerary</div>

                    </div>
                    <div className="d-flex flight-itenary-details-item-text justify-content-between align-items-center">
                      <div className="current-div1">
                        {checkIfUserStaff() &&
                          <><div className="vb-highlight-txt pt-2">Client Name: {bookingDetails.clientName}</div><div className="vb-highlight-txt mb-3">{bookingDetails.clientEmail}
                          </div></>}
                        <div
                          className={`itenary-confirmtaion-tag ${(!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Voided") ? 'Cancelled' : bookingDetails?.flightCancellationStatus}`}

                        >
                          {(!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Voided") ? 'Cancelled' : bookingDetails?.flightCancellationStatus}
                        </div>
                        <div className="pt-2">
                          {location.state.refId}
                        </div>

                      </div>
                      <div className="vb-highlight-txt pt-2">
                        {checkIfUserStaff() && <div className="" style={{ textAlign: "center" }}>
                          <p style={{ paddingTop: "10px" }}>
                            <span>Booking Source: </span>
                            <span>
                              {bookingDetails.bookingSource &&
                                bookingDetails.bookingSource}
                            </span>
                          </p>
                        </div>}

                        <div className="itenary-container-staff pt-2">
                          <div className="flight-itenary-details-item ">
                            <div className="flight-itenary-details-item-text ">
                              {bookingDetails.orderRefId}
                            </div>
                            <div className="flight-itenary-details-item-subtext pt-2">
                              {bookingDetails.orderRefId != "" ? "Reference No" : ""}
                            </div>
                            {checkIfUserStaff() && (
                              <>
                                <div className="flight-itenary-details-item-subtext pt-2">
                                  {bookingDetails.traceId}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {checkIfUserStaff() && <div class="text-center">
                        <div className="vb-highlight-txt pt-2">
                          <br /> ({bookingDetails.flight[0].pcc && bookingDetails.flight[0].pcc}
                          {" - "}
                          {pccList
                            .filter((id) =>
                              id.value.startsWith(bookingDetails.flight[0].pcc + ",")
                            )
                            .map(
                              ({ label }) => label.match(/\(([^)]+)\)/)[1]
                            )}
                          )
                        </div>

                        {checkIfUserStaff() && (
                          <div className="flight-itenary-details-item">
                            <div className="flight-itenary-details-item-text pt-2">
                              {bookingDetails.flight[0].pnr}
                            </div>
                            <div className="flight-itenary-details-item-subtext">
                              {bookingDetails.flight[0].pnr ? "PNR/Booking Reference" : ""}
                            </div>
                          </div>
                        )}
                      </div>}

                    </div>



                  </div>
                }

                {bookingDetails && bookingDetails?.flight && bookingDetails?.flight[0] && bookingDetails?.flight[0]?.segGroups && bookingDetails?.flight[0]?.segGroups.length > 0
                  ? (
                    <div>

                      {/* seggroup detail */}
                      {bookingDetails?.flight?.[0]?.segGroups?.map((segGroup, index) => (
                        <>
                          <div className="flight-itenary">
                            <div className="flight-itenary-header">
                              <div className="flight-itenary-title-container">
                                <div>
                                  {findAirportbyCode(segGroup.origin)} (
                                  {segGroup.origin})
                                </div>
                                <div className="flight-itenary-icon">
                                  <ArrowRightAltIcon></ArrowRightAltIcon>
                                </div>
                                <div>
                                  {findAirportbyCode(segGroup.destination)} (
                                  {segGroup.destination})
                                </div>
                              </div>
                              <div className="flight-itenary-date">
                                {moment(segGroup.departureOn).format(
                                  "Do MMM  YYYY"
                                )}
                              </div>
                            </div>
                            {segGroup?.segments !== undefined &&
                              segGroup?.segments.length > 0 ? (
                              segGroup?.segments.map((segment) => (
                                <div>
                                  <div className="flight-itenary-details">
                                    <div className="flight-itenary-details-item">
                                      <div className="flight-itenary-details-item-text">
                                        {segment.cabinClass}
                                      </div>
                                      <div className="flight-itenary-details-item-subtext">
                                        Class Of Service
                                      </div>
                                    </div>
                                    <div className="flight-itenary-details-item">
                                      <div className="flight-itenary-details-item-text">
                                        {findAirlinebyCode(
                                          segment.mrkAirline
                                        )}
                                      </div>
                                      <div className="flight-itenary-details-item-subtext">
                                        {segment.mrkAirline}-
                                        {segment.flightNum}
                                      </div>
                                    </div>
                                    <div className="flight-itenary-details-item">
                                      <div className="flight-itenary-details-item-text">
                                        {moment(segment.departureOn).format(
                                          "HH:mm"
                                        )}
                                      </div>
                                      <div className="flight-itenary-details-item-subtext">
                                        Departure
                                      </div>
                                    </div>
                                    {bookingDetails?.bookingSource !==
                                      "OfflineBooking" && (
                                        <div className="flight-itenary-details-item">
                                          <div className="flight-itenary-details-item-text">
                                            {getDuration(segment.duration)}
                                          </div>
                                          <div className="flight-itenary-details-item-subtext">
                                            Duration
                                          </div>
                                        </div>
                                      )}
                                    <div className="flight-itenary-details-item">
                                      <div className="flight-itenary-details-item-text">
                                        {moment(segment.arrivalOn).format(
                                          "HH:mm"
                                        )}
                                      </div>
                                      <div className="flight-itenary-details-item-subtext">
                                        Arrival
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flight-confirmation-details">
                                    <div className="flight-confirmation-details-item">
                                      <div className="flight-confirmation-details-item-text">
                                        Origin
                                      </div>
                                      <div className="flight-confirmation-details-item-subtext">
                                        {getAirportByCode(segment.origin)} -{" "}
                                        {segment.origin}
                                      </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                      <div className="flight-confirmation-details-item-text">
                                        Destination
                                      </div>
                                      <div className="flight-confirmation-details-item-subtext">
                                        {getAirportByCode(
                                          segment.destination
                                        )}{" "}
                                        - {segment.destination}
                                      </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                      <div className="flight-confirmation-details-item-text">
                                        PNR
                                      </div>
                                      <div className="flight-confirmation-details-item-subtext">
                                        {segment.pnr}
                                      </div>
                                    </div>
                                    <div className="flight-confirmation-details-item">
                                      <div className="flight-confirmation-details-item-text">
                                        Segment Status
                                      </div>
                                      <div className="flight-confirmation-details-item-subtext full-opacity">
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                          }}
                                        >

                                          {segment.flightTicketStatus !==
                                            undefined ? (
                                            (!checkIfUserStaff() && segment.flightTicketStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && segment.flightTicketStatus === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && segment.flightTicketStatus === "Voided") ? 'Cancel_Requested' : segment.flightTicketStatus

                                          ) : (
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >

                                              {
                                                (!checkIfUserStaff() && segment.flightTicketStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && segment.flightTicketStatus === "Void_Refunded") ? 'Refunded' : segment.flightTicketStatus
                                              }
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div></div>
                            )}

                          </div>



                        </>
                      ))}

                      <div className="itenary-container booking-padding-top-40">
                        <div>Passenger Details</div>
                      </div>
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer>
                          <Table>
                            <TableHead className="mui-table-header">
                              <TableCell align="center">
                                Passenger(s)
                              </TableCell>
                              <TableCell align="center">Gender</TableCell>
                              <TableCell align="center">
                                Date of Birth
                              </TableCell>
                              <TableCell align="center">Ticket(s)</TableCell>
                              <TableCell align="center">
                                Passport No
                              </TableCell>
                              <TableCell align="center">Seat</TableCell>
                              <TableCell align="center">Status</TableCell>
                            </TableHead>
                            <TableBody>
                              {bookingDetails?.flight[0]?.segGroups[1] ? (bookingDetails?.flight[0]?.segGroups[1]?.passengers !== undefined &&
                                bookingDetails?.flight[0]?.segGroups[1]?.passengers?.length > 0
                                ? bookingDetails?.flight[0]?.segGroups[1]?.passengers
                                  .sort(function (a, b) {
                                    if (a.paxType < b.paxType) {
                                      return -1;
                                    }
                                    if (a.paxType > b.paxType) {
                                      return 1;
                                    }
                                    return 0;

                                  }).map((passenger) => (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCell align="center">
                                        {passenger.title}{" "}
                                        {passenger.firstName}{" "}
                                        {passenger.lastName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {passenger.genderType}
                                      </TableCell>
                                      <TableCell align="center">
                                        {moment(passenger.dob).format(
                                          "Do MMM YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell align="center">
                                        {passenger.ticketNumber == ""
                                          ? `NA`
                                          : passenger.ticketNumber}
                                      </TableCell>
                                      <TableCell align="center">
                                        {passenger.passportNumber == ""
                                          ? `NA`
                                          : passenger.passportNumber}
                                      </TableCell>
                                      <TableCell align="center">
                                        {passenger.seatPref === ""
                                          ? `NA`
                                          : passenger.seatPref}
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "15px",
                                            fontWeight: "300",
                                          }}
                                        >
                                          {
                                            (!checkIfUserStaff() && passenger.flightTicketStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && passenger.flightTicketStatus === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && passenger.flightTicketStatus === "Voided") ? 'Cancel_Requested' : passenger.flightTicketStatus

                                          }
                                          {/* {passenger.flightTicketStatus &&
                                              passenger.flightTicketStatus} */}
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                : null) :
                                bookingDetails?.flight[0]?.segGroups[0]?.passengers !== undefined &&
                                  bookingDetails?.flight[0]?.segGroups[0]?.passengers?.length > 0
                                  ? bookingDetails?.flight[0]?.segGroups[0]?.passengers
                                    .sort(function (a, b) {
                                      if (a.paxType < b.paxType) {
                                        return -1;
                                      }
                                      if (a.paxType > b.paxType) {
                                        return 1;
                                      }
                                      return 0;

                                    }).map((passenger) => (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        <TableCell align="center">
                                          {passenger.title}{" "}
                                          {passenger.firstName}{" "}
                                          {passenger.lastName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {passenger.genderType}
                                        </TableCell>
                                        <TableCell align="center">
                                          {moment(passenger.dob).format(
                                            "Do MMM YYYY"
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          {passenger.ticketNumber == ""
                                            ? `NA`
                                            : passenger.ticketNumber}
                                        </TableCell>
                                        <TableCell align="center">
                                          {passenger.passportNumber == ""
                                            ? `NA`
                                            : passenger.passportNumber}
                                        </TableCell>
                                        <TableCell align="center">
                                          {passenger.seatPref === ""
                                            ? `NA`
                                            : passenger.seatPref}
                                        </TableCell>
                                        <TableCell>
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: "15px",
                                              fontWeight: "300",
                                            }}
                                          >
                                            {
                                              (!checkIfUserStaff() && passenger.flightTicketStatus === "Refund_Initiated") ? 'Cancelled' : (!checkIfUserStaff() && passenger.flightTicketStatus === "Void_Refunded") ? 'Refunded' : (!checkIfUserStaff() && passenger.flightTicketStatus === "Voided") ? 'Cancel_Requested' : passenger.flightTicketStatus
                                            }
                                            {/* {passenger.flightTicketStatus &&
                                              passenger.flightTicketStatus} */}
                                          </span>
                                        </TableCell>

                                      </TableRow>
                                    ))
                                  : null
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  )
                  : null}
                {checkIfUserStaff() && (
                  <div>
                    {bookingDetails &&
                      bookingDetails?.flightCancellationStatus &&
                      (bookingDetails?.flightCancellationStatus ===
                        "Cancel_Requested" ||
                        bookingDetails?.flightCancellationStatus ===
                        "Cancelled" ||
                        bookingDetails?.flightCancellationStatus ===
                        "Refund_Initiated" ||
                        bookingDetails?.flightCancellationStatus ===
                        "Refunded" ||
                        bookingDetails?.flightCancellationStatus ===
                        "Voided" ||
                        bookingDetails?.flightCancellationStatus ===
                        "Cancel_Rejected") && (
                        //(bookingDetails.flight[0].segGroups && bookingDetails.flight[0].segGroups.length > 1))  && (
                        <>
                          <div className="itenary-container booking-padding-top-40">
                            <div style={{ minWidth: "230px" }} className="mt-4">
                              Akbar Staff Display -
                            </div>
                            <div className="mt-4">
                              {bookingDetails && bookingDetails?.flight[0] && bookingDetails?.flight[0]?.currency ? (
                                <p
                                  style={{
                                    fontSize: "20px",
                                    marginRight: "780px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {
                                    bookingDetails?.flight[0]
                                      ?.currency
                                  }
                                </p>
                              ) : (
                                <p> </p>
                              )}
                            </div>
                          </div>
                          <div className="itenary-passenger-details">
                            <table className="textCenter itenary-passenger-table">
                              <thead>
                                <tr className="itenary-passenger-table-header">
                                  <th
                                    align="left"
                                    className="width20  itenary-passenger-table-header-pl-12 header-item-font-weight s-no itenary-passenger-view-table-header-item-initial "
                                  >
                                    Passenger(s)
                                  </th>
                                  <th
                                    align="left"
                                    className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    Ticket Amount
                                  </th>
                                  {showOnwardFare && (
                                    <th
                                      align="left"
                                      className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                    >
                                      Onward Fares
                                    </th>
                                  )}
                                  <th
                                    align="left"
                                    className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    Vendor Fee
                                  </th>
                                  <th
                                    align="left"
                                    className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    Airline Cancellation
                                  </th>
                                  <th
                                    align="left"
                                    className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    Supplier Service Fee
                                  </th>
                                  <th
                                    align="left"
                                    className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    STF
                                  </th>
                                  <th
                                    align="left"
                                    className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    RAF
                                  </th>
                                  <th
                                    align="left"
                                    className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    GF Service Fee
                                  </th>
                                  <th
                                    align="left"
                                    className="width10  itenary-passenger-table-header-pl-12 header-item-font-weight"
                                  >
                                    Net Refund
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {bookingDetails?.flight[0]?.cancellationRefundAmounts.map((psg) => {

                                  const passengerId = psg.paxId;

                                  const segGroupData = passengersDetails[passengerId];

                                  if (!segGroupData) {
                                    return null;
                                  }

                                  return (
                                    <tr className="itenary-passenger-table-item price-row">
                                      <td className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {psg.paxName} <span style={{ fontSize: "12px" }}>{"(" + psg.cityPair.join(',') + ")"}</span>
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        {Number(segGroupData.totalFare || "").toFixed(2)}
                                      </td>
                                      {showOnwardFare && (
                                        <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                          <input
                                            type="number"
                                            value={segGroupData.onwardFare}
                                            onChange={(e) => handleInputChange(e.target.value, passengerId, 'onwardFare')}
                                            style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                            disabled={psg.segType !== "return" || (psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                          />
                                        </td>
                                      )}
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <input
                                          type="number"
                                          value={segGroupData.vendorFee}
                                          onChange={(e) => handleInputChange(e.target.value, passengerId, 'vendorFee')}
                                          style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        />
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <input
                                          type="number"
                                          value={segGroupData.cancellationCharge}
                                          onChange={(e) => handleInputChange(e.target.value, passengerId, 'cancellationCharge')}
                                          style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        />
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <input
                                          type="number"
                                          value={segGroupData.supplierServiceCharge}
                                          onChange={(e) => handleInputChange(e.target.value, passengerId, 'supplierServiceCharge')}
                                          style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        />
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <input
                                          type="number"
                                          value={segGroupData.stfCharge}
                                          onChange={(e) => handleInputChange(e.target.value, passengerId, 'stfCharge')}
                                          style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        />
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <input
                                          type="number"
                                          value={segGroupData.rafCharge}
                                          onChange={(e) => handleInputChange(e.target.value, passengerId, 'rafCharge')}
                                          style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        />
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <input
                                          type="number"
                                          value={segGroupData.gfsServiceCharge}
                                          onChange={(e) => handleInputChange(e.target.value, passengerId, 'gfsServiceCharge')}
                                          style={{ width: "60px", height: "30px", padding: "5px", opacity: 0.5 }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        />
                                      </td>
                                      <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                        <button
                                          onClick={(e) => handleNetRefundChange(passengerId, e.target.value)}
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#a0155a",
                                            padding: "5px",
                                            border: "none",
                                            backgroundColor: "transparent",
                                          }}
                                          disabled={(psg.status === "Refunded" || psg.status === "Cancel_Rejected") || (psg.status === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)}
                                        >
                                          Calculate
                                        </button>
                                        <span>
                                          <div>
                                            <p><b>Total NetRefund:</b> {(segGroupData.supplierNetRefund)?.toFixed(2) || "0.0"}</p>
                                          </div>
                                        </span>
                                      </td>
                                    </tr>
                                  );

                                })}




                              </tbody>


                            </table>
                          </div>
                        </>
                      )}
                  </div>
                )}

                <div className="itenary-container booking-padding-top-40">
                  <div className="d-flex align-items-center mt-4">
                    <div className="me-1">Client Display </div>
                    <div className="me-4" style={{ fontSize: "20px" }}>
                    </div>
                  </div>
                </div>

                <div className="itenary-passenger-details">
                  <table className="textCenter  itenary-passenger-table mb-5">
                    <tr className="itenary-passenger-table-header">
                      <th
                        align="left"
                        className="width20  itenary-passenger-table-header-pl-12 header-item-font-weight s-no itenary-passenger-view-table-header-item-initial "
                      >
                        Passenger(s)
                      </th>
                      <th
                        align="left"
                        className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                      >
                        Ticket Amount
                      </th>
                      {!segTypeOnward &&
                        <th
                          align="left"
                          className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                        >
                          Onward Fares
                        </th>}
                      <th
                        align="left"
                        className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                      >
                        Cancellation Chargers
                      </th>
                      <th
                        align="left"
                        className="width10 itenary-passenger-table-header-pl-12 header-item-font-weight"
                      >
                        GF Service Fee
                      </th>
                      <th
                        align="left"
                        className="width10  itenary-passenger-table-header-pl-12 header-item-font-weight"
                      >
                        Net Refund
                      </th>
                    </tr>
                    {bookingDetails?.flight &&
                      bookingDetails?.flight[0] &&
                      bookingDetails?.flight[0]?.cancellationRefundAmounts.map((psg) => {
                        const passengerId = psg.paxId;

                        const passengerDetails = passengersDetails[passengerId];

                        if (!passengerDetails) {
                          return null;
                        }

                        return (
                          <tr key={passengerId} className="itenary-passenger-table-item price-row">
                            <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {psg.paxName}
                            </td>
                            <td align="center" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {Number(passengerDetails.totalFare)?.toFixed(2)}
                            </td>
                            {!segTypeOnward &&
                              (psg.segType == "return" ?
                                (<td align="center" className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                                  {checkIfUserStaff() ? Number(passengerDetails.onwardFare)?.toFixed(2) || "0.0" :
                                    !checkIfUserStaff() && psg.status === "Refunded" ? Number(passengerDetails.onwardFare)?.toFixed(2) : "-"}
                                </td>) : (<td align="center" className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">-</td>)
                              )}
                            <td align="center" className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {checkIfUserStaff() ? Number(passengerDetails.clientCancel)?.toFixed(2) || "0.0" :
                                !checkIfUserStaff() && psg.status === "Refunded" ? Number(passengerDetails.clientCancel)?.toFixed(2) : "-"}
                            </td>
                            <td align="center" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {checkIfUserStaff() ? Number(passengerDetails.clientGFSService)?.toFixed(2) || "0.0" :
                                !checkIfUserStaff() && psg.status === "Refunded" ? Number(passengerDetails.clientGFSService)?.toFixed(2) : "-"}
                            </td>
                            <td align="center" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {checkIfUserStaff() ? Number(passengerDetails.clientNetRefund)?.toFixed(2) || "0.0" :
                                !checkIfUserStaff() && psg.status === "Refunded" ? Number(passengerDetails.clientNetRefund)?.toFixed(2) : "-"}
                            </td>
                          </tr>
                        );
                      })
                    }
                    {!checkIfUserStaff() && bookingDetails?.flightCancellationStatus === "Refunded" ?
                      (< tr className="itenary-passenger-table-item price-row">
                        <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>
                        <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>
                        {!segTypeOnward && <td className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>}
                        <td className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>
                        <td align="right" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-bold">Total Amount:</td>
                        <td align="center" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-bold">
                          {Number(totalClientNetRefund)?.toFixed(2)}
                        </td>
                      </tr>) :
                      (checkIfUserStaff() && < tr className="itenary-passenger-table-item price-row">
                        <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>
                        <td className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>
                        {!segTypeOnward && <td className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>}
                        <td className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"></td>
                        <td align="right" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-bold">Total Amount:</td>
                        <td align="center" className="width10 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12 text-bold">
                          {Number(totalClientNetRefund)?.toFixed(2)}
                        </td>
                      </tr>)
                    }

                  </table>
                </div>

                {checkIfUserStaff() &&
                  historyDetail &&
                  historyDetail.length > 0 ? (
                  <>
                    <div className="itenary-container invoice-itenary-container booking-padding-top-64">
                      <div>History Details</div>
                    </div>
                    <div className="itenary-passenger-details">
                      <table className="textCenter  itenary-passenger-table">
                        <tr className="itenary-passenger-table-header">
                          <th
                            align="left"
                            className="width20  itenary-passenger-table-header-pl-12 header-item-font-weight s-no itenary-passenger-view-table-header-item-initial "
                          >
                            Booking Status
                          </th>
                          <th
                            align="left"
                            className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                          >
                            Actioned On
                          </th>
                          <th
                            align="left"
                            className="width15 itenary-passenger-table-header-pl-12 header-item-font-weight"
                          >
                            Actioned By
                          </th>
                          <th
                            align="left"
                            className="width20  itenary-passenger-table-header-pl-12 header-item-font-weight s-no itenary-passenger-view-table-header-item-final "
                          >
                            Remarks
                          </th>
                        </tr>
                        {historyDetail.map((item) => (
                          <tr className="itenary-passenger-table-item  price-row">
                            <td className="width10  itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {item.status}
                            </td>
                            <td
                              style={{ textAlign: "center !important" }}
                              className="width15 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12"
                            >
                              {item.actionedOn}
                            </td>
                            <td className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {item.actionBy}
                            </td>
                            <td className="width20 itenary-passenger-table-h-64 itenary-passenger-table-header-pl-12">
                              {item.remarks}
                            </td>
                          </tr>
                        ))}
                      </table>
                      <div>
                        <div className="itenary-container booking-padding-top-10">
                          <div>Staff Remarks</div>
                        </div>
                        <div>
                          <input
                            type="text"
                            value={staffremarks || ""}
                            className="remark-container"
                            onChange={(event) => {
                              setstaffRemarks(
                                (staffremarks) => event?.target?.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {checkIfUserStaff() &&
                  bookingDetails &&
                  bookingDetails?.flight &&
                  (bookingDetails?.flightCancellationStatus ===
                    "Cancel_Requested" ||
                    bookingDetails?.flightCancellationStatus ===
                    "Cancelled" ||
                    bookingDetails?.flightCancellationStatus ===
                    "Refund_Initiated" ||
                    bookingDetails?.flightCancellationStatus ===
                    "Refunded" ||
                    bookingDetails?.flightCancellationStatus ===
                    "Voided" ||
                    bookingDetails?.flightCancellationStatus ===
                    "Cancel_Rejected") && (
                    <div>
                      <div className="master-btn">
                        <div className="status-btn">
                          <td colSpan="4" className="padLR2vw padTB1vw">
                            <Select
                              defaultValue="None"
                              onChange={handleStatusChange}
                              disabled={bookingDetails?.flightCancellationStatus ===
                                "Refunded" || bookingDetails?.flightCancellationStatus ===
                                "Cancel_Rejected"}
                            >
                              <Option value="None">Status</Option>
                              <Option value="Cancelled">Cancelled</Option>
                              <Option value="Refund_Initiated">
                                Refund Initiated
                              </Option>
                              {(AuthorizedUsers.includes(user['email_id'])) == true && <Option value="Refunded">Refunded</Option>}
                              {(AuthorizedUsers.includes(user['email_id'])) == true && <Option value="Voided">Voided</Option>}
                              {bookingDetails &&
                                bookingDetails?.flight &&
                                (bookingDetails?.flightCancellationStatus ===
                                  "Cancel_Requested" ||
                                  bookingDetails?.flightCancellationStatus ===
                                  "Cancelled") && (
                                  <Option value="Cancel_Rejected">Cancel Rejected</Option>
                                )}
                            </Select>
                          </td>
                        </div>
                        <div className="sub-btn">
                          {(selectedcancelstatus === "None" ||
                            (bookingDetails?.flightCancellationStatus === "Refunded" ||
                              bookingDetails?.flightCancellationStatus === "Cancel_Rejected")
                            || (bookingDetails?.flightCancellationStatus === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)) ? (
                            <Button
                              variant="contained"
                              //className="refsubmit-btn disabled-btn"
                              className={`refsubmit-btn ${selectedcancelstatus === "None"
                                || (bookingDetails?.flightCancellationStatus === "Refunded" ||
                                  bookingDetails?.flightCancellationStatus === "Cancel_Rejected")
                                || (bookingDetails?.flightCancellationStatus === "Refund_Initiated" && (AuthorizedUsers.includes(user['email_id'])) == false)
                                ? "disabled-btn"
                                : ""
                                }`}
                              onClick={refundNow}
                              disabled
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              className="refsubmit-btn"
                              onClick={refundNow}

                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      </div>


                    </div>


                  )}

              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </>
        )}
      </div>
    </div >
  );
};



export default ViewCancellationDetails;
