import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./TopUp.css";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import getClientBalance from "../../functions/getClientBalance";
import { checkIfUserStaff } from "../../services/AuthService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
export default function TopUp() {
  const [state, setState] = useState({
    encRequest: "",
    accessCode: "",
    amount: "",
    Currency: "",
    clientId: 0,
    url: "",
    clientBalance: 0,
    transactionTokenID: 0,
    transactionTokens: []
  });
  const [open, setOpen] = React.useState(false);
  const [payToken, setPayToken] = useState(false);


  async function getClients(getClientId) {
    let filter = "id==" + getClientId;
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/ClientProfile?sorts=created&filters=" +
        filter
      )
      .then((response) => {

        setState((prev) => {
          return {
            ...prev,
            Currency: response?.data[0]?.clientpforile.currencyCode,
            paymentGateWayListId: response?.data[0]?.clientpforile.paymentGateWayListId,
          };
        });
      });
  }
  const getpaymentTokens = async (id) => {
    let URL = 'api/Configuration/PaymentGateway/GetPaymentTokensByClientId/';
    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        URL +
        id
      )
      .then((response) => {

        setState((prev) => {
          return {
            ...prev,
            transactionTokens: response?.data,
          };
        });
      })
  }
  useEffect(() => {
    console.log('asf')
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null && user !== undefined) {
      getClients(user["clientref_id"]);
      setState((prev) => {
        return { ...prev, clientId: user["clientref_id"] };
      });
      if (checkIfUserStaff() === false) {
        let getClientId = user["clientref_id"];
        getpaymentTokens(getClientId);
        setTimeout(async () => {
          let resp = await getClientBalance(getClientId);
          setState((prev) => {
            return {
              ...prev,
              clientBalance: resp?.balance,
            };
          });
        }, 3000);
      }
    }
  }, []);
  useEffect(() => {
    if (state.provider === "HDFC_CCAvenue") {
      if (state.accessCode && state.encRequest && state.url) {
        document.getElementById("paymentForm").submit();
      }
    } else if (state.provider === "WorldPay") {
      if (state.url) {
        document.getElementById("paymentWorldForm").click();
      }
      // else{
      //   getTransactionDetails(state.).then((response) => {
      //     setState((prev) => {
      //       return {
      //         ...prev,
      //         paymentId: response.paymentId,
      //         paymentStatus: response.paymentStatus,
      //         rechargeRefNo: response.rechargeRefNo,
      //         pgRefNo: response.pgRefNo,
      //         amount: response ? response.amount : 0,
      //         currency: response ? response.currency : "",
      //         failureMessage: response ? response.failureMessage : "",
      //       };
      //     });
      //     setOpen(true);
      //     console.log("paymentState", state);
      // })
      // }
    }
  }, [state]);
  const handlePayment = async (event) => {
    handleOpen();
    event.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    const url =
      process.env.REACT_APP_CORE_URL +
      "api/Configuration/PaymentGateway/MakePayment";
    let temp = JSON.stringify({
      ClientId: state.clientId,
      Amount: state.amount,
      Currency: state.Currency,
      PaymentTokenId: state.transactionTokenID ? state.transactionTokenID : 0,
      tokenize: state.paymentGateWayListId === 2 ? payToken : false
    });
    localStorage.setItem("paymentObj", temp);
    await axios
      .post(url, temp, {
        headers,
      })
      .then((resp) => {
        let response = resp.data;

        if (response?.provider === "HDFC_CCAvenue" && response?.encRequest && response?.access_code) {
          setState((prev) => {
            return {
              ...prev,
              encRequest: response?.encRequest,
              accessCode: response?.access_code,
              url: response?.paymentUrl,
              provider: response?.provider,
              transactionId: response?.transactionId,
              amount: response?.amount,
            };
          });
        } else {

          if (response.provider === "WorldPay" && response.transactionId && response.paymentUrl) {
            setState((prev) => {
              return {
                ...prev,
                encRequest: response?.encRequest,
                accessCode: response?.access_code,
                url: response?.paymentUrl,
                provider: response?.provider,
                transactionId: response?.transactionId,
                amount: response?.amount,
              };
            });
          } else if (response.provider === "WorldPay" && response.transactionId) {
            let url = `${window.location.origin}/Recharge/${response.transactionId}`
            window.open(url, '_parent');
          }
        }

        handleClose();
      })
      .catch((error) => {
        handleClose();
        console.error("Error submitting form:", error);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event) => {
    setState((prev) => {
      return {
        ...prev,
        transactionTokenID: event.target.value,
      };
    });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ flexGrow: 1 }}>
        <Grid style={{}} container spacing={8}>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <h3 className="fw-bold p-4">Add Funds</h3>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "80px",
            }}
          >
            {state.clientBalance ? (
              <div className="p-2 curr-bal-tu">
                <span className="floatL">Current Balance:</span>  <span><CurrencyCodeComponent currencyCode={state.Currency} /> {state.clientBalance}</span>
              </div>
            ) : (
              <div className="p-2 curr-bal-tu">
                <span className="floatL">Current Balance:</span> <span><CurrencyCodeComponent currencyCode={state.Currency} /> 0</span>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            {
              state?.transactionTokens?.length ?
                <Box sx={{ minWidth: '15vw', maxWidth: '15vw', float: 'right', border: '1px solid #ddd', margin: '10px 1vw', background: '#fff' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ fontWeight: 'bold', color: '#9f155a' }}>
                      Transaction Token
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state.transactionTokenID}
                      label="Transaction Token"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {
                        // state.transactionTokens && state.transactionTokens.length ?
                        state.transactionTokens.map((item => (
                          <MenuItem key={item.id} value={item.id}>cardNo: {item?.cardNo} Expiry: {(item?.cardExpiry).split(" ")[0]}</MenuItem>
                        )))
                        // : ''
                      }
                    </Select>
                  </FormControl>
                </Box>
                : ''
            }
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <TextField
              className="bg-light rounded paymentInput"
              value={state.amount}
              // type="number"
              onChange={(e) =>
                setState((prev) => {
                  return {
                    ...prev,
                    amount: e.target.value.replace(/\D/g, ""),
                  };
                })
              }
              id="outlined-helperText"
              label="Enter Amount"
              defaultValue=""
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#9f155a",
                  fontWeight: "bold",
                },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: "#9f155a" },
                },
                width: "300px",
              }}
            />

          </Grid>
          <Grid item xs={6}>
            <a
              style={{ display: "none" }}
              rel="noreferrer"
              href={state.url}
              id="paymentWorldForm"
            >
              IBoss Invoice
            </a>
            <form id="paymentForm" action={state.url} method="post">
              <input type="hidden" name="encRequest" value={state.encRequest} />
              <input
                type="hidden"
                name="access_code"
                value={state.accessCode}
              />
              <Button
                className="text-light"
                style={{
                  backgroundColor: state.amount >= 1 ? "#9f155a" : "grey",
                  width: "250px",
                  height: "50px",
                }}
                onClick={handlePayment}
                disabled={state.amount < 1}
              >
                Pay Now
              </Button>
            </form>
          </Grid>
          {state.paymentGateWayListId === 2 && state.transactionTokenID === 0 &&
            <>
              <Grid item xs={4} className=""></Grid>
              <Grid xs={4} className="">
                <FormControlLabel
                  control={<Checkbox
                    checked={payToken}
                    onChange={(event) => setPayToken(event.target.checked)}
                    color="primary" />}
                  label="" />
                <label className="tokencb-txt"><span className="text-bold">Tokenize</span> the card for easy future payments</label>
              </Grid>
              <Grid item xs={4} className=""></Grid>
            </>
          }
          <Grid xs={12} className="text-center mt-5">
            <p>
              By clicking on Pay Now, I agree with the{" "}
              <Link to="Terms" target="_blank" style={{ color: "black" }}>
                Privacy policy, Terms & Conditions
              </Link>{" "}
            </p>
            <br></br>
            <p style={{ textAlign: 'left', paddingLeft: '15vw', fontWeight: 'bold' }}>i. Payment Gateway Charges applicable based on Card/Bank on next page<br />

              ii. If Any Chargeback raised by Bank , its completely Get Fares clients responsibility to accept.<br />
              iii. Avoid using customer card </p>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
