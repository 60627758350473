import React, { useState, useEffect } from "react";
import "./SupplierCommissionBlock.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import { useParams } from "react-router-dom";
import SupplierCommissionBlockTable from "./SupplierCommissionBlockTable/SupplierCommissionBlockTable";
import SupplierCommissionBlockDrawer from "./SupplierCommissionBlockDrawer/SupplierCommissionBlockDrawer";
import AddSupplierCommissionBlockCard from "./AddSupplierCommissionBlockCard/AddSupplierCommissionBlockCard";
import { createTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SupplierCommissionBlock() {
  let { id } = useParams();
  let { name } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [commissionBlocks, setCommissionBlocks] = useState(null);
  const [supplierCommissionGroup, setSupplierCommissionGroup] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierCommID, setSupplierCommID] = useState("");
  const [airline, setAirline] = useState("");
  const [fareType, setfareType] = useState("");
  const [pccCode, setPCCCode] = useState("");
  const [cabinClass, setcabinClass] = useState("");
  const [rbd, setRbd] = useState("");
  const [pccList, setPccList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [alertText, setalertText] = useState("");
  const [mode, setMode] = useState("");
  const history = useHistory();
  useEffect(() => {
    loadCommissionBlocks(false);
    loadPCCDetails();
  }, []);
  useEffect(() => {
  }, [alertText, mode]);
  const theme = createTheme({
    overrides: {
      MuiDrawer: {
        paperAnchorDockedRight: {
          styleOverrides: {
            width: "200% !important",
          },
        },
      },
    },
  });

  async function loadCommissionBlocks(search) {
    let filter,supplierCommIDFilter="",fareTypeFilter = "", pccCodeFilter="";
    if(supplierCommID)
    {
      supplierCommIDFilter = ",id=="+supplierCommID;
    }
    if(fareType)
    {
      fareTypeFilter = ",faretype@="+fareType;
    }
    if(pccCode)
    {
      pccCodeFilter = ",pccCode@="+pccCode;
    }

    if(supplierCommID !== '' || fareType !== '' || pccCode !== '' ){
      if(search)
      {
        setPage(1)
      }
      filter = "supplierCommissionGroupId=="+id+supplierCommIDFilter+fareTypeFilter+pccCodeFilter+"&page=" + page;     
    } else {
      filter = "supplierCommissionGroupId==" + id+"&page=" + page;
    }

    // if(search){
    //   filter = "supplierCommissionGroupId=="+id+supplierCommIDFilter+fareTypeFilter+pccCodeFilter;      
    // } else {
    //   filter = "supplierCommissionGroupId==" + id;
    // }

    try{
      await axios
      .get(
        process.env.REACT_APP_CORE_URL +
          "api/Configuration/SupplierCommissionBlock?filters=" +
          filter+"&pageSize=25"
      )
      .then((response) => {
        
        setCommissionBlocks(response.data);
      });
    } catch(err) {
      // console.log("Error on searching commission Block =>",err);
    }

  }

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            for (var i = 0; i < response.data.length; i++) {
              if (
                vendorArray.some(
                  (item) => item.id === response.data[i].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                });
              } else {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label: response.data[i].pccConfigs.pccCode,
                });
              }
            }
            // console.log(tmpArray);
            setPccList(tmpArray);
          });
      });
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => {
    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = (text, mode) => {
    setalertText(text);
    setMode(mode);      
    setDrawer(false);
  setSnackbarOpen(true);
  loadCommissionBlocks(false);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="supplier-container">
      <div className="supplier-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-supplier-container">
              <div className="supplier-table-container">
                <div className="supplier-title-add-button-container">
                  <div className="supplier-heading">
                  <IconButton aria-label="Back" onClick={() => history.goBack()}>
                      <ArrowBack />
                    </IconButton>
                    <p>Supplier Commission Blocks - {name}</p>
                  </div>
                  <div className="search-icon-button-container">
                    <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon>
                    <div
                      className="add-supplier-button mt-0px pointer"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add New Block
                    </div>
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="supplier-card-container">
                    <AddSupplierCommissionBlockCard
                      setSupplierCommID={setSupplierCommID}
                      setfareType={setfareType}
                      setPCCCode={setPCCCode}
                      setcabinClass={setcabinClass}
                      loadCommissionBlocks={loadCommissionBlocks}
                    ></AddSupplierCommissionBlockCard>
                  </div>
                </Collapse>
                <SupplierCommissionBlockTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  commissionBlocks={commissionBlocks}
                  name={name}
                  setPage={setPage}
                  page={page}
                  pccList={pccList}
                  loadCommissionBlocks={loadCommissionBlocks}
                ></SupplierCommissionBlockTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        theme={theme}
        anchor="right"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <SupplierCommissionBlockDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            pccList={pccList}
            name={name}
            groupId={id}
            commissionBlocks={commissionBlocks}
          ></SupplierCommissionBlockDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        {alertText && mode == 'error' ? <Alert onClose={handleSnackBarClose} severity="error">
          {alertText}
        </Alert> : <Alert onClose={handleSnackBarClose} severity="success">
          {alertText}
        </Alert>}
      </Snackbar>
    </div>
  );
}

export default SupplierCommissionBlock;
