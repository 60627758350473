import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import "./Header.css";
import { isUserHasPermission } from "../../services/AuthService";
import { Link, useHistory, useLocation } from 'react-router-dom';
export function Megamenu({ closeMenu }) {
  const history = useHistory();
  const location = useLocation();

  const handleMenuClick = event => {
    if (event.target.className == "menu_item") {
      closeMenu();
    } else {
      return;
    }
  };

  const handleLinkClick = (event) => {

    if (location.pathname === '/OrderTraceReport') {
      localStorage.removeItem('orderTraceReportData');
      window.location.reload();
    } else {
      history.push('/OrderTraceReport');
    }
  };

  return <>
    <Container onClick={handleMenuClick}>
      <Row className="menu_row">
        <Col>
          <h5 className="menu_sub_header">Queue &amp; Others</h5>
          <Link className="menu_item" to="/PendingApprovalQueue">Pending Approval Queue</Link>
          <Link className="menu_item" to="/QueueTable">My Booking Queue</Link>
          <Link className="menu_item" to="/FSH">FSH</Link>
          <Link className="menu_item" to="/importbooking">Import Booking</Link>
              <Link className="menu_item" to="/OfflineBooking">
                Offline Booking
              </Link>
              <Link className="menu_item" to="/flight/ViewCancellation">
                View Cancellation
              </Link>
              <Link className="menu_item" to="/ImportPNR">
                Import PNR Ticketing
              </Link>
        </Col>
        <Col>
          <h5 className="menu_sub_header">Finance</h5>
          <Link className="menu_item" to="/DebitTransactions/0">Debit Transactions</Link>
          <Link className="menu_item" to="/Reco">Flight Reconcilliation</Link>
          {/* <Link className="menu_item" to="/Receipt">Receipt</Link> */}
          <Link className="menu_item" to="/BankInfo">Bank Info</Link>
              <Link className="menu_item" to="/OperatingOND">
                Operating OND
              </Link>
              <Link className="menu_item" to="/Recharge">
                Recharge Transactions
              </Link>
        </Col>
        <Col>
          <h5 className="menu_sub_header">Configurations</h5>
          <Link className="menu_item" to="/Supplier">PCC</Link>
          <Link className="menu_item" to="/SupplierCommissionGroup">Supplier Commission</Link>
          <Link className="menu_item" to="/SearchCategory">Search Category</Link>
              <Link className="menu_item" to="/L1CacheGroup">
                L1 Cache Group
              </Link>
          <Link className="menu_item" to="/TicketingConfiguration">Ticketing Configuration</Link>
          <Link className="menu_item" to="/MarkupGroup">Markup</Link>
              <Link className="menu_item" to="/PassthroughConfiguration">
                Passthrough Configuration
              </Link>
        </Col>
        <Col>
          <h5 className="menu_sub_header">Administration</h5>
          <Link className="menu_item" to="/ManageClients">Clients</Link>
          <Link className="menu_item" to="/ManageUser/0">Staffs</Link>
          <Link className="menu_item" to="/Vendor">Vendors</Link>
          {/* <Link className="menu_item" to="/RoleRights">Roles &amp; Rights</Link> */}
        </Col>
          <Col>
            <h5 className="menu_sub_header">Analytics</h5>
            <Link className="menu_item" to="/Dashboard">
              My Dashboard
            </Link>
            <Link className="menu_item" to="/ClientBalanceReport">
              Client Balance Report
            </Link>
            <Link className="menu_item" to="/OrderTraceReport" onClick={() => handleLinkClick()}>
              Order Trace Report
            </Link>
            <Link className="menu_item" to="/OfflineImportReport">
              Booking Report
            </Link>
          </Col>
      </Row>
    </Container>

  </>

  // return (
  //   <div className="megamenu-container">
  //     <Grid container spacing={1}>
  //       {(isUserHasPermission("ClientRead") ||
  //         isUserHasPermission("ClientWrite")) && (
  //           <Grid item xs={3} className="admin-item-border-right">
  //             <div
  //               className="admin-menu-item"
  //               onClick={() => redirectTo("/ManageClients")}
  //             >
  //               Clients
  //             </div>
  //           </Grid>
  //         )}
  //       <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/ManageUser/0")}
  //         >
  //           Staff User
  //         </div>
  //       </Grid>
  //       <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/Supplier")}
  //         >
  //           Pcc
  //         </div>
  //       </Grid>
  //       <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/Vendor")}
  //         >
  //           Vendor
  //         </div>
  //       </Grid>
  //       <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/SupplierCommissionGroup")}
  //         >
  //           Supplier Commission
  //         </div>
  //       </Grid>
  //       {/* <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item "
  //           onClick={() => redirectTo("/SupplierCommissionBlock/0")}
  //         >
  //           Supplier Commission Block
  //         </div>
  //       </Grid> */}
  //       <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item "
  //           onClick={() => redirectTo("/SearchCategory")}
  //         >
  //           Search Category
  //         </div>
  //       </Grid>
  //       {/* <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item "
  //           onClick={() => redirectTo("/SearchCategoryConfiguration")}
  //         >
  //           Search Category Configutaion
  //         </div>
  //       </Grid> */}
  //       <Grid item xs={3} className="admin-item-border-right">
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/RoleRights")}
  //         >
  //           Role Rights
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/PendingApprovalQueue")}
  //         >
  //           Pending Approval Queue
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/TicketingConfiguration")}
  //         >
  //           Ticketing Configuration
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/MyQueue")}
  //         >
  //           My Booking Queue
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/Dashboard")}
  //         >
  //           My Dashboard
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/MarkupGroup")}
  //         >
  //           Markup
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/FSH")}
  //         >
  //           FSH
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/DebitTransactions/0")}
  //         >
  //           Debit Transactions
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/importbooking")}
  //         >
  //           Import Booking
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right"
  //       >
  //         <div
  //           className="admin-menu-item"
  //           onClick={() => redirectTo("/Reco")}
  //         >
  //           Flight Reconcilliation
  //         </div>
  //       </Grid>
  //       <Grid
  //         item
  //         xs={3}
  //         className="admin-item-border-right admin-pointer-none"
  //       >
  //         <div className="admin-menu-item"></div>
  //       </Grid>
  //     </Grid>
  //   </div>
  // );
}
