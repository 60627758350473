import React, { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import './CCpayment.css';
import { Row } from "react-bootstrap";
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';

const CCpayment = ({
    cardNumber,
    setCardNumber,
    nameOnCard,
    setNameOnCard,
    expiryMonth,
    setExpiryMonth,
    expiryYear,
    setExpiryYear,
    cvv,
    setCvv,
    errCardFields,
    cardTypeAccepted
}) => {
    const [cvvVisible, setCvvVisible] = useState(false);
    const [cardError, setCardError] = useState('');
    const [nameError, setNameError] = useState('');
    const [cvvError, setCvvError] = useState('');

    const cardTypes = {
        VI: { digits: 16, cvv: 3, nameLimit: 26, startsWith: ['4'] },
        'MX/CA': { digits: 16, cvv: 3, nameLimit: 26, startsWith: ['2', '5'] },
        AX: { digits: 15, cvv: 4, nameLimit: 20, startsWith: ['3'] },
        TP: { digits: 15, cvv: 0, nameLimit: 20, startsWith: ['1'] },
        DS: { digits: 14, cvv: 3, nameLimit: 26, startsWith: ['30', '36', '38', '39'] }
    };

    const parseCardTypes = (acceptedTypes) => {
        const types = acceptedTypes.split(',').map(type => type.trim());
        let maxDigits = 0;
        let maxCvv = 0;
        let maxNameLimit = 0;
        let validStartDigits = [];

        types.forEach(type => {
            if (cardTypes[type]) {
                maxDigits = Math.max(maxDigits, cardTypes[type].digits);
                maxCvv = Math.max(maxCvv, cardTypes[type].cvv);
                maxNameLimit = Math.max(maxNameLimit, cardTypes[type].nameLimit);
                validStartDigits = [...validStartDigits, ...cardTypes[type].startsWith];
            }
        });

        return { maxDigits, maxCvv, maxNameLimit, validStartDigits };
    };

    const { maxDigits, maxCvv, maxNameLimit, validStartDigits } = parseCardTypes(cardTypeAccepted);

    const validateCardNumber = (number) => {
        if (number.length > maxDigits) {
            return `Card number must be ${maxDigits} digits`;
        }
        const isValidStartDigit = validStartDigits.some(digit => number.startsWith(digit));
        if (!isValidStartDigit) {
            return `Invalid card type. Accepted types start with ${validStartDigits.join(', ')}`;
        }
        return '';
    };

    const handleCardNumberChange = (e) => {
        const value = e.target.value;
        setCardNumber(value);
        const error = validateCardNumber(value);
        setCardError(error);
    };

    const validateCardDetails = () => {
        const isValidCardNumber = cardNumber.length === maxDigits && !cardError;
        const isValidCvv = maxCvv === 0 || cvv.length === maxCvv;
        const isValidName = nameOnCard.length <= maxNameLimit;

        setCardError(isValidCardNumber ? '' : `Card number must be ${maxDigits} digits`);
        setCvvError(isValidCvv ? '' : `CVV must be ${maxCvv} digits`);
        setNameError(isValidName ? '' : `Card holder name must be within ${maxNameLimit} characters`);

        return isValidCardNumber && isValidCvv && isValidName;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateCardDetails()) {
            console.log({ cardNumber, nameOnCard, expiryMonth, expiryYear, cvv });
        }
    };

    const months = [
        { label: "Jan (01)", value: "01" },
        { label: "Feb (02)", value: "02" },
        { label: "Mar (03)", value: "03" },
        { label: "Apr (04)", value: "04" },
        { label: "May (05)", value: "05" },
        { label: "Jun (06)", value: "06" },
        { label: "Jul (07)", value: "07" },
        { label: "Aug (08)", value: "08" },
        { label: "Sep (09)", value: "09" },
        { label: "Oct (10)", value: "10" },
        { label: "Nov (11)", value: "11" },
        { label: "Dec (12)", value: "12" },
    ];

    const getYears = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 20 }, (_, i) => (currentYear + i).toString());
    };

    const years = getYears();

    return (
        <>
            <form className="payment-form" onSubmit={handleSubmit}>
                <div className='d-flex'>
                    <div>
                        <div className="input-icon-wrapper">
                            <TextField
                                className='me-2'
                                style={{ width: "100%" }}
                                required
                                label="Card Number"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                                autoComplete="off"
                                error={!!cardError}
                                helperText={cardError}
                                inputProps={{ maxLength: maxDigits }}
                            />
                            <div className="input-icon">
                                <CreditCardIcon color='primary' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <TextField
                            style={{ width: "100%" }}
                            label="Card Holder Name"
                            variant="outlined"
                            size="small"
                            color="secondary"
                            value={nameOnCard}
                            onChange={(e) => setNameOnCard(e.target.value)}
                            autoComplete="off"
                            error={!!nameError}
                            helperText={nameError}
                            inputProps={{ maxLength: maxNameLimit }}
                        />
                    </div>
                </div>
                <div>
                    <label className='ms-1 mt-2 exp-label'>Expiry Date</label>
                    <div className="expiry-date-wrapper">
                        <Autocomplete
                            className='me-2'
                            style={{ width: "200px" }}
                            id="combo-box-demo"
                            options={months}
                            getOptionLabel={(option) => option.label}
                            value={months.find(month => month.value === expiryMonth) || null}
                            onChange={(event, newValue) => {
                                setExpiryMonth(newValue ? newValue.value : null);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Month" required variant="filled" autoComplete="off" />
                            )}
                        />
                        <Autocomplete
                            className='me-3'
                            style={{ width: "200px" }}
                            options={years}
                            getOptionLabel={(option) => (option ? option : "")}
                            value={expiryYear}
                            onChange={(event, newValue) => {
                                setExpiryYear(newValue || "");
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Year" required variant="filled" autoComplete="off" />
                            )}
                        />
                        <div className="input-icon-wrapper">
                            <TextField
                                type={cvvVisible ? 'text' : 'password'}
                                style={{ width: "200px" }}
                                label="CVV"
                                placeholder="CVV"
                                variant="outlined"
                                size="small"
                                color="secondary"
                                required
                                value={cvv}
                                onChange={(e) => setCvv(e.target.value)}
                                autoComplete="new-password"
                                error={!!cvvError}
                                helperText={cvvError}
                                inputProps={{ maxLength: maxCvv }}
                                disabled={maxCvv === 0}
                            />
                            <div className="input-icon" onClick={() => setCvvVisible(!cvvVisible)}>
                                {cvvVisible ? <VisibilityOffIcon color='primary' fontSize='small' /> : <VisibilityIcon color='primary' fontSize='small' />}
                            </div>
                        </div>
                    </div>
                </div>
                {errCardFields && <div className='text-danger'>*Please fill the fields with valid data</div>}
            </form>
        </>
    );
};

export default CCpayment;
